import { reset } from 'redux-form';
import {
  addingEditingConfigItem, clearConfigItems, setAlertErrorMessage, setConfigItemSelectedConfigGroup,
  setConfigItemDuplicateMode, setConfigItemSelectedDataType, setConfigItemSelectedUsedOn,
  setFunctionalPageMode,
  setConfigItemSelectedMultiline,
} from '../simple-action';
import { dataTypes, PAGE_MODE_TABLE, RXFORM_CONFIG_ITEM } from '../../../constant';
import { addConfigItem, editConfigItem } from '../../../helper';
import downloadConfigItemsAsync from './downloadConfigItemsAsync';

export default (order, name, value, label, description, allowOverride, encrypted, needRestart,
  configSection, enumOptions, multiLine) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingConfigItem(true));

    const { token } = getState().authentication;
    const {
      duplicateMode, tappedId, selectedDataType, selectedConfigGroup, selectedUsedOn,
    } = getState().uiConfigItem;
    const { data } = getState().configItems;

    const found = data[tappedId];

    let body = {
      order,
      name,
      value,
      label,
      description,
      dataType: selectedDataType || found.dataType,
      usedOn: selectedUsedOn || found.usedOn,
      allowOverride,
      encrypted,
      needRestart,
      configGroupId: selectedConfigGroup || found.configGroup.id,
      configSection,
      multiLine: multiLine === 'true',
    };

    body = body.dataType === dataTypes[7] ? { ...body, enumOptions } : body;
    if (tappedId && !duplicateMode) {
      const bodyEdit = { id: tappedId, ...body };
      await editConfigItem(bodyEdit, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addConfigItem(body, token);
      if (duplicateMode) { dispatch(setConfigItemDuplicateMode(false)); }
    }

    dispatch(setConfigItemSelectedConfigGroup(''));
    dispatch(setConfigItemSelectedDataType(''));
    dispatch(setConfigItemSelectedUsedOn(''));
    dispatch(setConfigItemSelectedMultiline(''));
    dispatch(reset(RXFORM_CONFIG_ITEM));
    dispatch(clearConfigItems());
    dispatch(downloadConfigItemsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingConfigItem(false));
  }
};
