/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Button, Grid,
} from '@material-ui/core';
import { FunctionalPage } from '../../../../component';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_FORMAT, RXSTATE_MY_JOB_PAGE, RXSTATE_MY_JOBS,
  RXFORM_MY_JOB, RXFIELD_MY_JOB_NAME, RXFIELD_MY_JOB_DESCRIPTION,
  RXFIELD_MY_JOB_USER_ID, RXFIELD_MY_JOB_USER_NAME, RXFIELD_MY_JOB_JOB_STATUS,
  RXFIELD_MY_JOB_JOB_STATUS_NOTES, RXFIELD_MY_JOB_PROGRESS, RXFIELD_MY_JOB_PROGRESS_NOTES,
  RXFIELD_MY_JOB_START_TIME, RXFIELD_MY_JOB_END_TIME, RXFIELD_MY_JOB_DELETED_AFTER, JOB_STATUS,
  RESULT_TYPE, FILTER_TYPE_DROPDOWN, RXFIELD_MY_JOB_RESULT_TYPE,
  DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape } from '../../type';


const MyJobPage = ({
  initialValues,
  canceling, downloading, downloadingDeleting, handleSubmit, onAppear,
  onApplyAdvancedFilterPressed, onCancelAdvancedFilterPressed,
  onChangePage, onChangePageSize, onRefresh, onResetAdvancedFilterPressed,
  onSearchBarTextChanged, onSortPressed, onViewPressed, tappedId,
  onDownloadPressed, onContextMenuPressed, onConfirmContextMenuPressed,
  currentJobStatus,
}) => {
  const renderDownloadButton = (resultFilePath, fileName) => {
    if (resultFilePath) {
      return (
        <Button
          onClick={() => onDownloadPressed(resultFilePath, fileName)}
        >
          {LocalizedString.myJobPage.buttonCaptionDownload}
        </Button>

      );
    }
    return null;
  };

  return (
    <FunctionalPage
      data={RXSTATE_MY_JOBS}
      uiPage={RXSTATE_MY_JOB_PAGE}
      moreMenus={[
        {
          caption: LocalizedString.myJobPage.buttonCaptionDelete,
          disabled: currentJobStatus === JOB_STATUS[0]
            || currentJobStatus === JOB_STATUS[1] || downloadingDeleting,
          onPress: () => onContextMenuPressed(tappedId,
            GlobalLocalizedString.common.msgDeleteConfirmation),
        },
        {
          caption: GlobalLocalizedString.common.buttonCaptionCancel,
          disabled: currentJobStatus === JOB_STATUS[2]
            || currentJobStatus === JOB_STATUS[3]
            || currentJobStatus === JOB_STATUS[4]
            || canceling,
          onPress: () => onContextMenuPressed(tappedId,
            GlobalLocalizedString.common.msgCancelConfirmation),
        },
      ]}
      filterColumns={[
        {
          title: LocalizedString.myJobPage.labelCreatedDate,
          field: 'createdDate',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_FORMAT,
        },
        {
          title: LocalizedString.myJobPage.labelStartTime,
          field: 'startTime',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_FORMAT,
        },
        {
          title: LocalizedString.myJobPage.labelEndTime,
          field: 'endTime',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_FORMAT,
        },
        {
          title: LocalizedString.myJobPage.labelJobStatus, field: 'jobStatus', type: FILTER_TYPE_DROPDOWN, data: JOB_STATUS,
        },
        {
          title: LocalizedString.myJobPage.labelResultType, field: 'resultType', type: FILTER_TYPE_DROPDOWN, data: RESULT_TYPE,
        },
      ]}
      tableColumns={[
        {
          title: LocalizedString.myJobPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        {
          title: LocalizedString.myJobPage.labelName, field: 'name', sorting: !downloading,
        },
        { title: LocalizedString.myJobPage.labelJobStatus, field: 'jobStatus', sorting: !downloading },
        {
          title: LocalizedString.myJobPage.labelStartTime,
          field: 'startTime',
          sorting: !downloading,
          render: ({ startTime }) => (startTime
            ? toMoment(startTime).format(DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE) : null),
        },
        {
          title: LocalizedString.myJobPage.labelEndTime,
          field: 'endTime',
          sorting: !downloading,
          render: ({ endTime }) => (endTime
            ? toMoment(endTime).format(DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE) : null),
        },
        {
          title: LocalizedString.myJobPage.labelProgress,
          field: 'progress',
          render: ({ progress }) => `${progress}%`,
          sorting: !downloading,
        },
        {
          title: LocalizedString.myJobPage.labelNotes, field: 'progressNotes', sorting: !downloading,
        },
        {
          title: LocalizedString.myJobPage.labelCreatedDate,
          field: 'createdDate',
          sorting: !downloading,
          render: ({ createdDate }) => (createdDate
            ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
      ]}
      handleSubmit={handleSubmit}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmContextMenuPressed}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={onViewPressed}
      title={LocalizedString.myJobPage.title}
      disableCreate
      disableEdit
      disableDelete
      useFullWidth
      usefullWidthDialog
    >
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_MY_JOB_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.myJobPage.placeholderName}
              label={LocalizedString.myJobPage.placeholderName}
              disabled
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_MY_JOB_DESCRIPTION}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.myJobPage.placeholderDescription}
              label={LocalizedString.myJobPage.placeholderDescription}
              disabled
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_MY_JOB_USER_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.myJobPage.placeholderUserID}
              label={LocalizedString.myJobPage.placeholderUserID}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_MY_JOB_USER_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.myJobPage.placeholderUserName}
              label={LocalizedString.myJobPage.placeholderUserName}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_MY_JOB_RESULT_TYPE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.myJobPage.placeholderResultType}
              label={LocalizedString.myJobPage.placeholderResultType}
              disabled
            />
          </Grid>

          <Grid item>
            {renderDownloadButton(initialValues?.resultFilePath, initialValues.name)}
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_MY_JOB_JOB_STATUS}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.myJobPage.placeholderJobStatus}
              label={LocalizedString.myJobPage.placeholderJobStatus}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_MY_JOB_JOB_STATUS_NOTES}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.myJobPage.placeholderJobStatusNotes}
              label={LocalizedString.myJobPage.placeholderJobStatusNotes}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_MY_JOB_PROGRESS}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.myJobPage.placeholderProgress}
              label={`${LocalizedString.myJobPage.placeholderProgress} (%)`}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_MY_JOB_PROGRESS_NOTES}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.myJobPage.placeholderProgressNotes}
              label={LocalizedString.myJobPage.placeholderProgressNotes}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_MY_JOB_START_TIME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.myJobPage.placeholderStartTime}
              label={LocalizedString.myJobPage.placeholderStartTime}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_MY_JOB_END_TIME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.myJobPage.placeholderEndTime}
              label={LocalizedString.myJobPage.placeholderEndTime}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_MY_JOB_DELETED_AFTER}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.myJobPage.placeholderDeletedAfter}
              label={LocalizedString.myJobPage.placeholderDeletedAfter}
              disabled
            />
          </Grid>
        </Grid>
      </Grid>
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_MY_JOB,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(MyJobPage);

MyJobPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  canceling: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onContextMenuPressed: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  currentJobStatus: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
};
