import {
  Account, AccountCardDetails, AccountGroup, AccountKey, AccountQuestion, Bell,
  CalendarMultipleCheck, CalendarSearch, CalendarText, Cogs, CommentTextMultiple, CreditCard,
  Database, EyeCheck, FileSearch, FileTable, Forum, Gamepad, LabelPercent, Link as IconLink,
  NewspaperVariant, PackageVariant, PlayNetwork, SackPercent, ViewDashboard, ViewList, Briefcase,
  FileDocumentBoxPlus,
  FilePdfOutline,
} from 'mdi-material-ui';
import {
  ROUTE_NAME_DASHBOARD,
  ROUTE_NAME_AUTHENTICATION, ROUTE_NAME_SUMMARY, ROUTE_NAME_SERVICE, ROUTE_NAME_SCOPE,
  ROUTE_NAME_API_KEY, ROUTE_NAME_TOKEN, ROUTE_NAME_PERMISSION, ROUTE_NAME_ROLE, ROUTE_NAME_USER,
  ROUTE_NAME_PERSONALIZATION, ROUTE_NAME_APPLICATION, ROUTE_NAME_BANNER, ROUTE_NAME_PROFILE,
  ROUTE_NAME_MASTER_DATA, ROUTE_NAME_PROVINCE, ROUTE_NAME_CITY, ROUTE_NAME_COMPANY,
  ROUTE_NAME_BRANCH, ROUTE_NAME_DISTRICT, ROUTE_NAME_SUBDISTRICT, ROUTE_NAME_POPUP_BANNER,
  MENUID_ROOT, MENUID_DASHBOARD, MENUID_AUTHENTICATION,
  MENUID_MASTERDATA, MENUID_PERSONALIZATION,
  MENUID_AUTHENTICATION_APIKEY, MENUID_AUTHENTICATION_PERMISSION, MENUID_AUTHENTICATION_ROLE,
  MENUID_AUTHENTICATION_SCOPE, MENUID_AUTHENTICATION_SERVICE, MENUID_AUTHENTICATION_SUMMARY,
  MENUID_AUTHENTICATION_TOKEN, MENUID_AUTHENTICATION_USER,
  MENUID_MASTERDATA_BRANCH, MENUID_MASTERDATA_CITY, MENUID_MASTERDATA_COMPANY,
  MENUID_MASTERDATA_DISTRICT, MENUID_MASTERDATA_PROVINCE, MENUID_MASTERDATA_SUBDISTRICT,
  MENUID_PERSONALIZATION_APPLICATION, MENUID_PERSONALIZATION_BANNER,
  MENUID_PERSONALIZATION_PROFILE, PERMISSION_OPERATOR_AND,
  MENUID_AUTHENTICATION_REGISTRATION_ATTEMPT,
  ROUTE_NAME_AUTH_REGISTRATION_ATTEMPT,
  MENUID_AUTHENTICATION_PASSWORD_RESET_ATTEMPT,
  ROUTE_NAME_AUTH_PASSWORD_RESET_ATTEMPT,
  MENUID_PERSONALIZATION_POPUP_BANNER,
  MENUID_MASTERDATA_WORKING_SHIFT, ROUTE_NAME_WORKING_SHIFT,
} from './constant';
import {
  MENUID_CONFIGURATION, MENUID_CONFIGURATION_CONFIG_ACTION, MENUID_CONFIGURATION_CONFIGGROUP,
  MENUID_CONFIGURATION_CONFIGITEM, MENUID_CONFIGURATION_CONFIG_VALUE,
  ROUTE_NAME_CONFIG_ACTION, ROUTE_NAME_CONFIGURATION, ROUTE_NAME_CONFIG_GROUP,
  ROUTE_NAME_CONFIG_ITEM, ROUTE_NAME_CONFIG_VALUE,
  LocalizedStringConfiguration,
  MENUID_CONFIGURATION_PROFILE_SYNC,
  ROUTE_NAME_PROFILE_SYNC,
} from './module/configuration';
import {
  MENUID_ATTENDANCE_MANAGEMENT, MENUID_ATTENDANCE_MANAGEMENT_SUMMARY,
  MENUID_ATTENDANCE_MANAGEMENT_HISTORY, MENUID_ATTENDANCE_MANAGEMENT_ADDITIONAL_FIELD,
  ROUTE_NAME_ATTENDANCE_SUMMARY, ROUTE_NAME_ATTENDANCE_MANAGEMENT, ROUTE_NAME_HISTORY,
  ROUTE_NAME_ADDITIONAL_FIELD,
  LocalizedStringAttendance,
} from './module/attendance';
import {
  MENUID_FAQ_MANAGEMENT, MENUID_FAQ_MANAGEMENT_FAQ, MENUID_FAQ_MANAGEMENT_FAQ_CATEGORY,
  ROUTE_NAME_FAQ, ROUTE_NAME_FAQ_CATEGORY, ROUTE_NAME_FAQ_MANAGEMENT,
  LocalizedStringFAQ,
} from './module/faq';
import {
  MENUID_PDF_MANAGEMENT, MENUID_PDF_MANAGEMENT_ANNOTATION_DESIGNER,
  ROUTE_NAME_ANNOTATION_DESIGNER, ROUTE_NAME_PDF_MANAGEMENT,
  LocalizedStringPDF,
} from './module/pdf';
import LocalizedString from './localization';
import {
  MENUID_NOTIFICATION, MENUID_NOTIFICATION_EMAIL, MENUID_NOTIFICATION_NOTIFICATION_TASK,
  MENUID_NOTIFICATION_PUSH_NOTIFICATION, MENUID_NOTIFICATION_SMS, MENUID_NOTIFICATION_WHATSAPP,
  ROUTE_NAME_EMAIL, ROUTE_NAME_NOTIFICATION, ROUTE_NAME_NOTIFICATION_TASK,
  ROUTE_NAME_PUSH_NOTIFICATION, ROUTE_NAME_SMS, ROUTE_NAME_WHATSAPP,
  LocalizedStringNotification,
} from './module/notification';
import {
  MENUID_PACKET_MANAGEMENT, MENUID_PACKET_MANAGEMENT_PACKET, MENUID_PACKET_MANAGEMENT_PACKET_TYPE,
  MENUID_PACKET_MANAGEMENT_PICKUP_LOCATION, MENUID_PACKET_MANAGEMENT_PACKET_SUMMARY,
  ROUTE_NAME_PACKET, ROUTE_NAME_PACKET_MANAGEMENT, ROUTE_NAME_PACKET_TYPE,
  ROUTE_NAME_PICKUP_LOCATION, ROUTE_NAME_PACKET_SUMMARY,
  LocalizedStringPacket,
} from './module/packet';
import {
  MENUID_ACTIVITY_MANAGEMENT, MENUID_ACTIVITY_MANAGEMENT_ACTIVITY,
  MENUID_ACTIVITY_MANAGEMENT_ACTIVITY_TYPE, MENUID_ACTIVITY_MANAGEMENT_CUSTOMER,
  MENUID_ACTIVITY_MANAGEMENT_INDUSTRY,
  ROUTE_NAME_ACTIVITY_MANAGEMENT, ROUTE_NAME_ACTIVITY, ROUTE_NAME_ACTIVITY_TYPE,
  ROUTE_NAME_CUSTOMER, ROUTE_NAME_INDUSTRY,
  LocalizedStringActivityManagement,
} from './module/activity';
import {
  MENUID_BCARD, MENUID_BCARD_COMPANY_MAPPING, MENUID_BCARD_INFO_MAPPING,
  ROUTE_NAME_BCARD, ROUTE_NAME_COMPANY_MAPPING, ROUTE_NAME_INFO_MAPPING,
  LocalizedStringBcard,
} from './module/bcard';
import {
  MENUID_SHORTLINK, MENUID_SHORTLINK_SHORTLINK_SCREEN,
  ROUTE_NAME_SHORTLINK_MENU, ROUTE_NAME_SHORTLINK_SCREEN,
  LocalizedStringShortlink,
} from './module/shortlink';
import {
  MENUID_AUDIT_MANAGEMENT, MENUID_AUDIT_MANAGEMENT_AUDIT_TRAIL,
  ROUTE_NAME_AUDIT_MANAGEMENT, ROUTE_NAME_AUDIT_TRAIL,
  LocalizedStringAudit,
} from './module/audit';
import {
  MENUID_POINT, MENUID_POINT_PURCHASE_REQUEST, MENUID_POINT_ACHIEVEMENT, MENUID_POINT_LEADER_BOARD,
  MENUID_POINT_POINT, MENUID_POINT_POINT_SUBMISSION, MENUID_POINT_POINT_TRANSACTION,
  MENUID_POINT_VOID_POINT_REQUEST,
  ROUTE_NAME_POINT, ROUTE_NAME_PURCHASE_REQUEST, ROUTE_NAME_POINT_ACHIEVEMENT,
  ROUTE_NAME_POINT_LEADER_BOARD, ROUTE_NAME_POINT_POINT, ROUTE_NAME_POINT_POINT_SUBMISSION,
  ROUTE_NAME_POINT_POINT_TRANSACTION, ROUTE_NAME_VOID_POINT_REQUEST,
  LocalizedStringPoint,
} from './module/point';
import {
  MENUID_VOUCHER, MENUID_VOUCHER_CATEGORY, MENUID_VOUCHER_TOKEN, MENUID_VOUCHER_TOKEN_REQUEST,
  MENUID_VOUCHER_VOUCHER, MENUID_VOUCHER_USAGE, ROUTE_NAME_VOUCHER_USAGE,
  ROUTE_NAME_VOUCHER, ROUTE_NAME_VOUCHER_CATEGORY, ROUTE_NAME_VOUCHER_TOKEN_REQUEST,
  ROUTE_NAME_VOUCHER_TOKEN, ROUTE_NAME_VOUCHER_VOUCHER,
  MENUID_VOUCHER_TOKEN_IMPORT, ROUTE_NAME_VOUCHER_TOKEN_IMPORT,
  LocalizedStringVoucher,
  MENUID_VOUCHER_PURCHASE_REQUEST, ROUTE_NAME_VOUCHER_PURCHASE_REQUEST,
  MENUID_VOUCHER_PROMOTED_MERCHANT, ROUTE_NAME_VOUCHER_PROMOTED_MERCHANT,
  MENUID_VOUCHER_TOKEN_ASSIGNMENT, ROUTE_NAME_VOUCHER_TOKEN_ASSIGNMENT,
  MENUID_VOUCHER_GIFT_VOUCHER_REDEMPTION, ROUTE_NAME_VOUCHER_GIFT_VOUCHER_REDEMPTION,
} from './module/voucher';
import {
  MENUID_DISCUSSION, MENUID_DISCUSSION_DISCUSSION_COMMENT, MENUID_DISCUSSION_REPORTED_COMMENT,
  MENUID_DISCUSSION_DISCUSSION,
  ROUTE_NAME_DISCUSSION_MENU, ROUTE_NAME_DISCUSSION_DISCUSSION_COMMENT,
  ROUTE_NAME_DISCUSSION_REPORTED_COMMENT, ROUTE_NAME_DISCUSSION,
  LocalizedStringDiscussion,
} from './module/discussion';
import {
  MENUID_FORUM, MENUID_FORUM_CATEGORY, MENUID_FORUM_POST,
  ROUTE_NAME_FORUM, ROUTE_NAME_FORUM_CATEGORY, ROUTE_NAME_FORUM_POST,
  LocalizedStringForum,
} from './module/forum';
import {
  MENUID_GAMEQR, MENUID_GAMEQR_CATEGORY, MENUID_GAMEQR_CHALLENGE,
  ROUTE_NAME_GAMEQR, ROUTE_NAME_GAMEQR_CATEGORY, ROUTE_NAME_GAMEQR_CHALLENGE,
  ROUTE_NAME_GAMEQR_SCANNED_CODE, MENUID_GAMEQR_SCANNED_CODE,
  LocalizedStringGameQR, ROUTE_NAME_GAMEQR_COMPLETED_CHALLENGE,
  MENUID_GAMEQR_COMPLETED_CHALLENGE,
} from './module/game-qr';
import {
  ROUTE_NAME_NEWS_MANAGEMENT, ROUTE_NAME_NEWS, ROUTE_NAME_NEWS_CATEGORY,
  MENUID_NEWS_MANAGEMENT, MENUID_NEWS_MANAGEMENT_NEWS_CATEGORY,
  MENUID_NEWS_MANAGEMENT_NEWS, LocalizedStringNews,
} from './module/news';
import {
  MENUID_PARTNER, MENUID_PARTNER_MERCHANT,
  ROUTE_NAME_PARTNER, ROUTE_NAME_PARTNER_MERCHANT,
  LocalizedStringPartner,
} from './module/partner';
import {
  LocalizedStringEvent,
  MENUID_EVENT, MENUID_EVENT_LOCATION,
  ROUTE_NAME_EVENT, ROUTE_NAME_SESSION_CATEGORY, ROUTE_NAME_LOCATION,
  MENUID_EVENT_CATEGORY, ROUTE_NAME_EVENT_CATEGORY, MENUID_EVENT_SESSION_CATEGORY,
  MENUID_EVENT_SPONSOR_CATEGORY, ROUTE_NAME_SPONSOR_CATEGORY, MENUID_EVENT_ROOM, ROUTE_NAME_ROOM,
  MENUID_EVENT_SPEAKER, MENUID_EVENT_SPONSOR, ROUTE_NAME_SPONSOR,
  ROUTE_NAME_SPEAKER,
  MENUID_EVENT_SESSION,
  ROUTE_NAME_SESSION,
  MENUID_EVENT_EVENT,
  MENUID_EVENT_EVENT_INVITATION,
  ROUTE_NAME_EVENT_INVITATION,
  MENUID_EVENT_SESSION_INVITATION,
  ROUTE_NAME_SESSION_INVITATION,
  MENUID_EVENT_EVENT_REGISTRATION,
  ROUTE_NAME_EVENT_REGISTRATION,
  MENUID_EVENT_SESSION_REGISTRATION,
  ROUTE_NAME_SESSION_REGISTRATION,
} from './module/event';
import {
  MENUID_ACTIVITY_REWARD, MENUID_ACTIVITY_REWARD_DAILY_CHECKIN,
  ROUTE_NAME_ACTIVITY_REWARD, ROUTE_NAME_DAILY_CHECKIN,
  MENUID_ACTIVITY_REWARD_REGISTRATION_REFERRAL, ROUTE_NAME_REGISTRATION_REFERRAL,
} from './module/activity-reward/constant';
import { LocalizedStringActivityReward } from './module/activity-reward';
import {
  MENUID_CAPTCHA, MENUID_CAPTCHA_REQUEST,
  ROUTE_NAME_CAPTCHA_MENU, ROUTE_NAME_CAPTCHA_REQUEST,
  LocalizedStringCaptcha,
} from './module/captcha';
import {
  LocalizedStringDForm,
  MENUID_DFORM, MENUID_DFORM_FIELD, MENUID_DFORM_FORM,
  ROUTE_NAME_DFORM, MENUID_DFORM_RESPONSE,
  ROUTE_NAME_DFORM_FIELD, ROUTE_NAME_DFORM_FORM, ROUTE_NAME_DFORM_RESPONSE,
  MENUID_DFORM_PREVIEW, ROUTE_NAME_DFORM_PREVIEW,
} from './module/dform';
import {
  LocalizedStringPayment, MENUID_PAYMENT, MENUID_PAYMENT_PAYMENT, ROUTE_NAME_PAYMENT_MENU,
  ROUTE_NAME_PAYMENT,
} from './module/payment';
import {
  LocalizedStringEventOperator,
  MENUID_EVENT_OPERATOR, MENUID_EVENT_OPERATOR_MAPPING, MENUID_EVENT_OPERATOR_INVITATION,
  ROUTE_NAME_EVENT_OPERATOR, ROUTE_NAME_EVENT_OPERATOR_MAPPING,
  ROUTE_NAME_EVENT_OPERATOR_INVITATION, MENUID_EVENT_OPERATOR_REGISTRATION,
  ROUTE_NAME_EVENT_OPERATOR_REGISTRATION,
  MENUID_EVENT_OPERATOR_EVENT,
  ROUTE_NAME_EVENT_OPERATOR_EVENT,
} from './module/event-operator';
import {
  LocalizedStringSessionOperator,
  MENUID_SESSION_OPERATOR_MAPPING, MENUID_SESSION_OPERATOR_INVITATION,
  MENUID_SESSION_OPERATOR_SESSION, MENUID_SESSION_OPERATOR_REGISTRATION,
  ROUTE_NAME_SESSION_OPERATOR_MAPPING, ROUTE_NAME_SESSION_OPERATOR_INVITATION,
  ROUTE_NAME_SESSION_OPERATOR_SESSION, ROUTE_NAME_SESSION_OPERATOR_REGISTRATION,
} from './module/session-operator';
import {
  LocalizedStringUserJob,
  MENUID_USER_JOB, MENUID_USER_JOB_JOB,
  ROUTE_NAME_JOB, ROUTE_NAME_USER_JOB, MENUID_USER_JOB_MY_JOB,
  ROUTE_NAME_MY_JOB,
} from './module/user-job';
import {
  MENUID_DOCUMENT_GENERATOR, LocalizedStringDocumentGenerator, ROUTE_DOCUMENT_GENERATOR,
  MENUID_DOCUMENT_GENERATOR_TEMPLATE, ROUTE_DOCUMENT_GENERATOR_TEMPLATE,
  MENUID_DOCUMENT_GENERATOR_DOCUMENT, ROUTE_DOCUMENT_GENERATOR_DOCUMENT,
} from './module/document-generator';


const menuList = [
  {
    id: MENUID_DASHBOARD,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedString.common.labelWelcome,
    value: ROUTE_NAME_DASHBOARD,
    icon: ViewDashboard,
    selected: false,
  },
  {
    id: MENUID_AUTHENTICATION,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedString.common.labelAuthentication,
    value: ROUTE_NAME_AUTHENTICATION,
    icon: AccountKey,
    selected: false,
  },
  {
    id: MENUID_PERSONALIZATION,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedString.common.labelPersonalization,
    value: ROUTE_NAME_PERSONALIZATION,
    icon: Account,
    selected: false,
  },
  {
    id: MENUID_MASTERDATA,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedString.common.labelMasterData,
    value: ROUTE_NAME_MASTER_DATA,
    icon: Database,
    selected: false,
  },
  {
    id: MENUID_CONFIGURATION,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringConfiguration.configurationMenu.title,
    value: ROUTE_NAME_CONFIGURATION,
    icon: Cogs,
    selected: false,
  },
  {
    id: MENUID_AUDIT_MANAGEMENT,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringAudit.auditManagementMenu.title,
    value: ROUTE_NAME_AUDIT_MANAGEMENT,
    icon: FileSearch,
    selected: false,
  },
  {
    id: MENUID_EVENT,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringEvent.eventMenu.title,
    value: ROUTE_NAME_EVENT,
    icon: CalendarText,
    selected: false,
  },
  {
    id: MENUID_EVENT_OPERATOR,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringEventOperator.eventOperatorMenu.title,
    value: ROUTE_NAME_EVENT_OPERATOR,
    icon: CalendarSearch,
    selected: false,
  },
  {
    id: MENUID_ATTENDANCE_MANAGEMENT,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringAttendance.attendanceManagementMenu.title,
    value: ROUTE_NAME_ATTENDANCE_MANAGEMENT,
    icon: CalendarMultipleCheck,
    selected: false,
  },
  {
    id: MENUID_ACTIVITY_MANAGEMENT,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringActivityManagement.activityManagementMenu.title,
    value: ROUTE_NAME_ACTIVITY_MANAGEMENT,
    icon: FileTable,
    selected: false,
  },
  {
    id: MENUID_NEWS_MANAGEMENT,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedString.common.labelNewsManagement,
    value: ROUTE_NAME_NEWS_MANAGEMENT,
    icon: NewspaperVariant,
    selected: false,
  },
  {
    id: MENUID_PACKET_MANAGEMENT,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringPacket.packetMenu.title,
    value: ROUTE_NAME_PACKET_MANAGEMENT,
    icon: PackageVariant,
    selected: false,
  },
  {
    id: MENUID_BCARD,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringBcard.businessCard.title,
    value: ROUTE_NAME_BCARD,
    icon: AccountCardDetails,
    selected: false,
  },
  {
    id: MENUID_FORUM,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringForum.forumMenu.title,
    value: ROUTE_NAME_FORUM,
    icon: Forum,
    selected: false,
  },
  {
    id: MENUID_ACTIVITY_REWARD,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringActivityReward.activityRewardMenu.title,
    value: ROUTE_NAME_ACTIVITY_REWARD,
    icon: PlayNetwork,
    selected: false,
  },
  {
    id: MENUID_GAMEQR,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringGameQR.gameQRMenu.title,
    value: ROUTE_NAME_GAMEQR,
    icon: Gamepad,
    selected: false,
  },
  {
    id: MENUID_POINT,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringPoint.pointMenu.title,
    value: ROUTE_NAME_POINT,
    icon: SackPercent,
    selected: false,
  },
  {
    id: MENUID_VOUCHER,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringVoucher.voucherMenu.title,
    value: ROUTE_NAME_VOUCHER,
    icon: LabelPercent,
    selected: false,
  },
  {
    id: MENUID_PARTNER,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringPartner.partnerMenu.title,
    value: ROUTE_NAME_PARTNER,
    icon: AccountGroup,
    selected: false,
  },
  {
    id: MENUID_FAQ_MANAGEMENT,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringFAQ.faqManagementMenu.title,
    value: ROUTE_NAME_FAQ_MANAGEMENT,
    icon: AccountQuestion,
    selected: false,
  },
  {
    id: MENUID_PDF_MANAGEMENT,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringPDF.pdfManagementMenu.title,
    value: ROUTE_NAME_PDF_MANAGEMENT,
    icon: FilePdfOutline,
    selected: false,
  },
  {
    id: MENUID_PAYMENT,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringPayment.paymentMenu.title,
    value: ROUTE_NAME_PAYMENT_MENU,
    icon: CreditCard,
    selected: false,
  },
  {
    id: MENUID_DFORM,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringDForm.dFormMenu.title,
    value: ROUTE_NAME_DFORM,
    icon: ViewList,
    selected: false,
  },
  {
    id: MENUID_DISCUSSION,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringDiscussion.discussionMenu.title,
    value: ROUTE_NAME_DISCUSSION_MENU,
    icon: CommentTextMultiple,
    selected: false,
  },
  {
    id: MENUID_SHORTLINK,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringShortlink.shortlinkMenu.title,
    value: ROUTE_NAME_SHORTLINK_MENU,
    icon: IconLink,
    selected: false,
  },
  {
    id: MENUID_CAPTCHA,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringCaptcha.captchaMenu.title,
    value: ROUTE_NAME_CAPTCHA_MENU,
    icon: EyeCheck,
    selected: false,
  },
  {
    id: MENUID_USER_JOB,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringUserJob.userJobMenu.title,
    value: ROUTE_NAME_USER_JOB,
    icon: Briefcase,
    selected: false,
  },
  {
    id: MENUID_NOTIFICATION,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringNotification.notificationMenu.title,
    value: ROUTE_NAME_NOTIFICATION,
    icon: Bell,
    selected: false,
  },
  {
    id: MENUID_DOCUMENT_GENERATOR,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringDocumentGenerator.documentGeneratorMenu.title,
    value: ROUTE_DOCUMENT_GENERATOR,
    icon: FileDocumentBoxPlus,
    selected: false,
  },
  {
    id: MENUID_AUTHENTICATION_SUMMARY,
    level: 2,
    parentId: MENUID_AUTHENTICATION,
    label: LocalizedString.summaryScreen.title,
    value: ROUTE_NAME_SUMMARY,
    icon: null,
    selected: false,
    permissions: ['AUTH_SUMMARY_ACCESS_USING'],
  },
  {
    id: MENUID_AUTHENTICATION_SERVICE,
    level: 2,
    parentId: MENUID_AUTHENTICATION,
    label: LocalizedString.serviceScreen.title,
    value: ROUTE_NAME_SERVICE,
    icon: null,
    selected: false,
    permissions: ['AUTH_CREATE_SERVICE'],
  },
  {
    id: MENUID_AUTHENTICATION_SCOPE,
    level: 2,
    parentId: MENUID_AUTHENTICATION,
    label: LocalizedString.scopeScreen.title,
    value: ROUTE_NAME_SCOPE,
    icon: null,
    selected: false,
    permissions: ['AUTH_CREATE_SCOPE'],
  },
  {
    id: MENUID_AUTHENTICATION_APIKEY,
    level: 2,
    parentId: MENUID_AUTHENTICATION,
    label: LocalizedString.apiKeyScreen.title,
    value: ROUTE_NAME_API_KEY,
    icon: null,
    selected: false,
    permissions: ['AUTH_CREATE_APIKEY'],
  },
  {
    id: MENUID_AUTHENTICATION_TOKEN,
    level: 2,
    parentId: MENUID_AUTHENTICATION,
    label: LocalizedString.tokenScreen.title,
    value: ROUTE_NAME_TOKEN,
    icon: null,
    selected: false,
    permissions: ['AUTH_GET_TOKEN'],
  },
  {
    id: MENUID_AUTHENTICATION_PERMISSION,
    level: 2,
    parentId: MENUID_AUTHENTICATION,
    label: LocalizedString.permissionScreen.title,
    value: ROUTE_NAME_PERMISSION,
    icon: null,
    selected: false,
    permissions: ['AUTH_CREATE_PERMISSION'],
  },
  {
    id: MENUID_AUTHENTICATION_ROLE,
    level: 2,
    parentId: MENUID_AUTHENTICATION,
    label: LocalizedString.roleScreen.title,
    value: ROUTE_NAME_ROLE,
    icon: null,
    selected: false,
    permissions: ['AUTH_CREATE_ROLE'],
  },
  {
    id: MENUID_AUTHENTICATION_USER,
    level: 2,
    parentId: MENUID_AUTHENTICATION,
    label: LocalizedString.userScreen.title,
    value: ROUTE_NAME_USER,
    icon: null,
    selected: false,
    permissions: ['AUTH_EDIT_USER'],
  },
  {
    id: MENUID_AUTHENTICATION_REGISTRATION_ATTEMPT,
    level: 2,
    parentId: MENUID_AUTHENTICATION,
    label: LocalizedString.registrationAttemptScreen.title,
    value: ROUTE_NAME_AUTH_REGISTRATION_ATTEMPT,
    icon: null,
    selected: false,
    permissions: ['AUTH_GET_REGISTRATION_ATTEMPT'],
  },
  {
    id: MENUID_AUTHENTICATION_PASSWORD_RESET_ATTEMPT,
    level: 2,
    parentId: MENUID_AUTHENTICATION,
    label: LocalizedString.passwordResetAttemptScreen.title,
    value: ROUTE_NAME_AUTH_PASSWORD_RESET_ATTEMPT,
    icon: null,
    selected: false,
    permissions: ['AUTH_GET_PASSWORD_RESET_ATTEMPT'],
  },
  {
    id: MENUID_PERSONALIZATION_APPLICATION,
    level: 2,
    parentId: MENUID_PERSONALIZATION,
    label: LocalizedString.applicationScreen.title,
    value: ROUTE_NAME_APPLICATION,
    icon: null,
    selected: false,
    permissions: ['CORE_CREATE_APPLICATION'],
  },
  {
    id: MENUID_PERSONALIZATION_BANNER,
    level: 2,
    parentId: MENUID_PERSONALIZATION,
    label: LocalizedString.bannerScreen.title,
    value: ROUTE_NAME_BANNER,
    icon: null,
    selected: false,
    permissions: ['CORE_CREATE_BANNER'],
  },
  {
    id: MENUID_PERSONALIZATION_POPUP_BANNER,
    level: 2,
    parentId: MENUID_PERSONALIZATION,
    label: LocalizedString.popUpBannerScreen.title,
    value: ROUTE_NAME_POPUP_BANNER,
    icon: null,
    selected: false,
    permissions: ['CORE_CREATE_POPUP_BANNER'],
  },
  {
    id: MENUID_PERSONALIZATION_PROFILE,
    level: 2,
    parentId: MENUID_PERSONALIZATION,
    label: LocalizedString.profileScreen.title,
    value: ROUTE_NAME_PROFILE,
    icon: null,
    selected: false,
    permissions: ['MDATA_EDIT_PROFILE'],
  },
  {
    id: MENUID_MASTERDATA_PROVINCE,
    level: 2,
    parentId: MENUID_MASTERDATA,
    label: LocalizedString.provinceScreen.title,
    value: ROUTE_NAME_PROVINCE,
    icon: null,
    selected: false,
    permissions: ['MDATA_CREATE_PROVINCE'],
  },
  {
    id: MENUID_MASTERDATA_CITY,
    level: 2,
    parentId: MENUID_MASTERDATA,
    label: LocalizedString.cityScreen.title,
    value: ROUTE_NAME_CITY,
    icon: null,
    selected: false,
    permissions: ['MDATA_CREATE_CITY'],
  },
  {
    id: MENUID_MASTERDATA_DISTRICT,
    level: 2,
    parentId: MENUID_MASTERDATA,
    label: LocalizedString.districtScreen.title,
    value: ROUTE_NAME_DISTRICT,
    icon: null,
    selected: false,
    permissions: ['MDATA_CREATE_DISTRICT'],
  },
  {
    id: MENUID_MASTERDATA_SUBDISTRICT,
    level: 2,
    parentId: MENUID_MASTERDATA,
    label: LocalizedString.subdistrictScreen.title,
    value: ROUTE_NAME_SUBDISTRICT,
    icon: null,
    selected: false,
    permissions: ['MDATA_CREATE_SUBDISTRICT'],
  },
  {
    id: MENUID_MASTERDATA_COMPANY,
    level: 2,
    parentId: MENUID_MASTERDATA,
    label: LocalizedString.companyScreen.title,
    value: ROUTE_NAME_COMPANY,
    icon: null,
    selected: false,
    permissions: [
      'MDATA_CREATE_COMPANY', 'MDATA_DELETE_COMPANY',
      'MDATA_EDIT_COMPANY', 'MDATA_GET_COMPANY',
    ],
  },
  {
    id: MENUID_MASTERDATA_BRANCH,
    level: 2,
    parentId: MENUID_MASTERDATA,
    label: LocalizedString.branchScreen.title,
    value: ROUTE_NAME_BRANCH,
    icon: null,
    selected: false,
    permissions: ['MDATA_CREATE_BRANCH'],
  },
  {
    id: MENUID_MASTERDATA_WORKING_SHIFT,
    level: 2,
    parentId: MENUID_MASTERDATA,
    label: LocalizedString.workingShiftPage.title,
    value: ROUTE_NAME_WORKING_SHIFT,
    icon: null,
    selected: false,
    permissions: ['MDATA_CREATE_WORKINGSHIFT'],
  },
  {
    id: MENUID_ATTENDANCE_MANAGEMENT_SUMMARY,
    level: 2,
    parentId: MENUID_ATTENDANCE_MANAGEMENT,
    label: LocalizedStringAttendance.attendanceSummaryScreen.title,
    value: ROUTE_NAME_ATTENDANCE_SUMMARY,
    icon: null,
    selected: false,
    permissions: [
      'ATTENDANCE_SUMMARY_CLOCKIN', 'ATTENDANCE_SUMMARY_CLOCKIN_LOCATION',
      'ATTENDANCE_SUMMARY_CLOCKOUT', 'ATTENDANCE_SUMMARY_CLOCKOUT_LOCATION',
      'ATTENDANCE_SUMMARY_TOTAL_CLOCKIN', 'ATTENDANCE_SUMMARY_TOTAL_CLOCKOUT',
    ],
  },
  {
    id: MENUID_ATTENDANCE_MANAGEMENT_HISTORY,
    level: 2,
    parentId: MENUID_ATTENDANCE_MANAGEMENT,
    label: LocalizedStringAttendance.historyScreen.title,
    value: ROUTE_NAME_HISTORY,
    icon: null,
    selected: false,
    permissions: ['ATTENDANCE_GET_HISTORY'],
  },
  {
    id: MENUID_ATTENDANCE_MANAGEMENT_ADDITIONAL_FIELD,
    level: 2,
    parentId: MENUID_ATTENDANCE_MANAGEMENT,
    label: LocalizedStringAttendance.additionalFieldScreen.title,
    value: ROUTE_NAME_ADDITIONAL_FIELD,
    icon: null,
    selected: false,
    permissions: ['ATTENDANCE_CREATE_ADDITIONAL_FIELD'],
  },
  {
    id: MENUID_NEWS_MANAGEMENT_NEWS_CATEGORY,
    level: 2,
    parentId: MENUID_NEWS_MANAGEMENT,
    label: LocalizedStringNews.newsCategoryScreen.title,
    value: ROUTE_NAME_NEWS_CATEGORY,
    icon: null,
    selected: false,
    permissions: ['NEWS_CREATE_NEWS_CATEGORY'],
  },
  {
    id: MENUID_NEWS_MANAGEMENT_NEWS,
    level: 2,
    parentId: MENUID_NEWS_MANAGEMENT,
    label: LocalizedStringNews.newsScreen.title,
    value: ROUTE_NAME_NEWS,
    icon: null,
    selected: false,
    permissions: ['NEWS_CREATE_NEWS'],
  },
  {
    id: MENUID_CONFIGURATION_CONFIG_ACTION,
    level: 2,
    parentId: MENUID_CONFIGURATION,
    label: LocalizedStringConfiguration.configActionScreen.title,
    value: ROUTE_NAME_CONFIG_ACTION,
    icon: null,
    selected: false,
    permissions: ['CONFIG_CREATE_CONFIG_GROUP'],
  },
  {
    id: MENUID_CONFIGURATION_CONFIGGROUP,
    level: 2,
    parentId: MENUID_CONFIGURATION,
    label: LocalizedStringConfiguration.configGroupScreen.title,
    value: ROUTE_NAME_CONFIG_GROUP,
    icon: null,
    selected: false,
    permissions: ['CONFIG_CREATE_CONFIG_GROUP'],
  },
  {
    id: MENUID_CONFIGURATION_CONFIGITEM,
    level: 2,
    parentId: MENUID_CONFIGURATION,
    label: LocalizedStringConfiguration.configItemScreen.title,
    value: ROUTE_NAME_CONFIG_ITEM,
    icon: null,
    selected: false,
    permissions: ['CONFIG_CREATE_CONFIG_ITEM'],
  },
  {
    id: MENUID_CONFIGURATION_CONFIG_VALUE,
    level: 2,
    parentId: MENUID_CONFIGURATION,
    label: LocalizedStringConfiguration.configValueScreen.title,
    value: ROUTE_NAME_CONFIG_VALUE,
    icon: null,
    selected: false,
    permissions: ['CONFIG_EDIT_CONFIG_ITEM'],
  },
  {
    id: MENUID_CONFIGURATION_PROFILE_SYNC,
    level: 2,
    parentId: MENUID_CONFIGURATION,
    label: LocalizedStringConfiguration.profileSyncScreen.title,
    value: ROUTE_NAME_PROFILE_SYNC,
    icon: null,
    selected: false,
    permissions: ['AUTHEXT_USER_SYNC', 'MDATA_RESYNC_MASTERDATA'],
  },
  {
    id: MENUID_AUDIT_MANAGEMENT_AUDIT_TRAIL,
    level: 2,
    parentId: MENUID_AUDIT_MANAGEMENT,
    label: LocalizedStringAudit.auditTrailPage.title,
    value: ROUTE_NAME_AUDIT_TRAIL,
    icon: null,
    selected: false,
    permissions: ['AUDITTRAIL_GET_AUDITTRAIL'],
  },
  {
    id: MENUID_SHORTLINK_SHORTLINK_SCREEN,
    level: 2,
    parentId: MENUID_SHORTLINK,
    label: LocalizedStringShortlink.shortlinkScreen.title,
    value: ROUTE_NAME_SHORTLINK_SCREEN,
    icon: null,
    selected: false,
    permissions: ['SHORTLINK_CREATE_LINK'],
  },
  {
    id: MENUID_CAPTCHA_REQUEST,
    level: 2,
    parentId: MENUID_CAPTCHA,
    label: LocalizedStringCaptcha.captchaRequestScreen.title,
    value: ROUTE_NAME_CAPTCHA_REQUEST,
    icon: null,
    selected: false,
    permissions: ['CAPTCHA_GET_CAPTCHA_REQUEST'],
  },
  {
    id: MENUID_FAQ_MANAGEMENT_FAQ_CATEGORY,
    level: 2,
    parentId: MENUID_FAQ_MANAGEMENT,
    label: LocalizedStringFAQ.faqCategoryPage.title,
    value: ROUTE_NAME_FAQ_CATEGORY,
    icon: null,
    selected: false,
    permissions: ['NEWS_CREATE_FAQ_CATEGORY'],
  },
  {
    id: MENUID_FAQ_MANAGEMENT_FAQ,
    level: 2,
    parentId: MENUID_FAQ_MANAGEMENT,
    label: LocalizedStringFAQ.faqPage.title,
    value: ROUTE_NAME_FAQ,
    icon: null,
    selected: false,
    permissions: ['NEWS_CREATE_FAQ'],
  },
  {
    id: MENUID_PDF_MANAGEMENT_ANNOTATION_DESIGNER,
    level: 2,
    parentId: MENUID_PDF_MANAGEMENT,
    label: LocalizedStringPDF.annotationDesignerPage.title,
    value: ROUTE_NAME_ANNOTATION_DESIGNER,
    icon: null,
    selected: false,
    permissions: ['NEWS_CREATE_FAQ'],
  },
  {
    id: MENUID_USER_JOB_MY_JOB,
    level: 2,
    parentId: MENUID_USER_JOB,
    label: LocalizedStringUserJob.myJobPage.title,
    value: ROUTE_NAME_MY_JOB,
    icon: null,
    selected: false,
    permissions: ['USERJOB_GET_MYJOB'],
  },
  {
    id: MENUID_NOTIFICATION_NOTIFICATION_TASK,
    level: 2,
    parentId: MENUID_NOTIFICATION,
    label: LocalizedStringNotification.notificationTaskScreen.title,
    value: ROUTE_NAME_NOTIFICATION_TASK,
    icon: null,
    selected: false,
    permissions: ['CORE_SEND_SIMPLE_NOTIFICATION'],
  },
  {
    id: MENUID_NOTIFICATION_EMAIL,
    level: 2,
    parentId: MENUID_NOTIFICATION,
    label: LocalizedStringNotification.emailScreen.title,
    value: ROUTE_NAME_EMAIL,
    icon: null,
    selected: false,
    permissions: ['MESSAGING_GET_EMAIL'],
  },
  {
    id: MENUID_NOTIFICATION_SMS,
    level: 2,
    parentId: MENUID_NOTIFICATION,
    label: LocalizedStringNotification.smsScreen.title,
    value: ROUTE_NAME_SMS,
    icon: null,
    selected: false,
    permissions: ['MESSAGING_GET_SMS'],
  },
  {
    id: MENUID_NOTIFICATION_WHATSAPP,
    level: 2,
    parentId: MENUID_NOTIFICATION,
    label: LocalizedStringNotification.whatsappScreen.title,
    value: ROUTE_NAME_WHATSAPP,
    icon: null,
    selected: false,
    permissions: ['MESSAGING_GET_WHATSAPP'],
  },
  {
    id: MENUID_NOTIFICATION_PUSH_NOTIFICATION,
    level: 2,
    parentId: MENUID_NOTIFICATION,
    label: LocalizedStringNotification.pushNotificationScreen.title,
    value: ROUTE_NAME_PUSH_NOTIFICATION,
    icon: null,
    selected: false,
    permissions: ['MESSAGING_GET_PUSH_NOTIF'],
  },
  {
    id: MENUID_PACKET_MANAGEMENT_PACKET_SUMMARY,
    level: 2,
    parentId: MENUID_PACKET_MANAGEMENT,
    label: LocalizedStringPacket.packetSummaryScreen.title,
    value: ROUTE_NAME_PACKET_SUMMARY,
    icon: null,
    selected: false,
    permissions: ['PACKET_SUMMARY'],
  },
  {
    id: MENUID_PACKET_MANAGEMENT_PICKUP_LOCATION,
    level: 2,
    parentId: MENUID_PACKET_MANAGEMENT,
    label: LocalizedStringPacket.pickupLocationScreen.title,
    value: ROUTE_NAME_PICKUP_LOCATION,
    icon: null,
    selected: false,
    permissions: ['PACKET_CREATE_LOCATION'],
  },
  {
    id: MENUID_PACKET_MANAGEMENT_PACKET_TYPE,
    level: 2,
    parentId: MENUID_PACKET_MANAGEMENT,
    label: LocalizedStringPacket.packetTypeScreen.title,
    value: ROUTE_NAME_PACKET_TYPE,
    icon: null,
    selected: false,
    permissions: ['PACKET_CREATE_PACKET_TYPE'],
  },
  {
    id: MENUID_PACKET_MANAGEMENT_PACKET,
    level: 2,
    parentId: MENUID_PACKET_MANAGEMENT,
    label: LocalizedStringPacket.packetScreen.title,
    value: ROUTE_NAME_PACKET,
    icon: null,
    selected: false,
    permissions: ['PACKET_CREATE_PACKET'],
  },
  {
    id: MENUID_ACTIVITY_MANAGEMENT_INDUSTRY,
    level: 2,
    parentId: MENUID_ACTIVITY_MANAGEMENT,
    label: LocalizedStringActivityManagement.industryScreen.title,
    value: ROUTE_NAME_INDUSTRY,
    icon: null,
    selected: false,
    permissions: ['ACTIVITY_CREATE_INDUSTRY'],
  },
  {
    id: MENUID_ACTIVITY_MANAGEMENT_CUSTOMER,
    level: 2,
    parentId: MENUID_ACTIVITY_MANAGEMENT,
    label: LocalizedStringActivityManagement.customerScreen.title,
    value: ROUTE_NAME_CUSTOMER,
    icon: null,
    selected: false,
    permissions: ['ACTIVITY_CREATE_CUSTOMER'],
  },
  {
    id: MENUID_ACTIVITY_MANAGEMENT_ACTIVITY_TYPE,
    level: 2,
    parentId: MENUID_ACTIVITY_MANAGEMENT,
    label: LocalizedStringActivityManagement.activityTypeScreen.title,
    value: ROUTE_NAME_ACTIVITY_TYPE,
    icon: null,
    selected: false,
    permissions: ['ACTIVITY_CREATE_ACTIVITY_TYPE'],
  },
  {
    id: MENUID_ACTIVITY_MANAGEMENT_ACTIVITY,
    level: 2,
    parentId: MENUID_ACTIVITY_MANAGEMENT,
    label: LocalizedStringActivityManagement.activityScreen.title,
    value: ROUTE_NAME_ACTIVITY,
    icon: null,
    selected: false,
    permissions: ['ACTIVITY_CREATE_ACTIVITY'],
  },
  {
    id: MENUID_BCARD_COMPANY_MAPPING,
    level: 2,
    parentId: MENUID_BCARD,
    label: LocalizedStringBcard.companyMappingScreen.title,
    value: ROUTE_NAME_COMPANY_MAPPING,
    icon: null,
    selected: false,
    permissions: ['BCARD_CREATE_COMPANY_MAPPING'],
  },
  {
    id: MENUID_BCARD_INFO_MAPPING,
    level: 2,
    parentId: MENUID_BCARD,
    label: LocalizedStringBcard.infoMappingScreen.title,
    value: ROUTE_NAME_INFO_MAPPING,
    icon: null,
    selected: false,
    permissions: ['BCARD_CREATE_INFO_MAPPING'],
  },
  {
    id: MENUID_POINT_POINT_SUBMISSION,
    level: 2,
    parentId: MENUID_POINT,
    label: LocalizedStringPoint.pointSubmissionPage.title,
    value: ROUTE_NAME_POINT_POINT_SUBMISSION,
    icon: null,
    selected: false,
    permissions: ['POINT_GET_POINT_SUBMISSION'],
  },
  {
    id: MENUID_POINT_POINT,
    level: 2,
    parentId: MENUID_POINT,
    label: LocalizedStringPoint.pointPage.title,
    value: ROUTE_NAME_POINT_POINT,
    icon: null,
    selected: false,
    permissions: ['POINT_GET_POINT'],
  },
  {
    id: MENUID_POINT_PURCHASE_REQUEST,
    level: 2,
    parentId: MENUID_POINT,
    label: LocalizedStringPoint.purchaseRequestPage.title,
    value: ROUTE_NAME_PURCHASE_REQUEST,
    icon: null,
    selected: false,
    permissions: ['POINT_GET_PURCHASE_REQUEST'],
  },
  {
    id: MENUID_POINT_POINT_TRANSACTION,
    level: 2,
    parentId: MENUID_POINT,
    label: LocalizedStringPoint.pointTransactionPage.title,
    value: ROUTE_NAME_POINT_POINT_TRANSACTION,
    icon: null,
    selected: false,
    permissions: ['POINT_GET_TRANSACTION'],
  },
  {
    id: MENUID_POINT_ACHIEVEMENT,
    level: 2,
    parentId: MENUID_POINT,
    label: LocalizedStringPoint.achievementPage.title,
    value: ROUTE_NAME_POINT_ACHIEVEMENT,
    icon: null,
    selected: false,
    permissions: ['AUTH_GET_USER', 'POINT_GET_BALANCE', 'POINT_GET_ACHIEVEMENT', 'POINT_GET_TRANSACTION'],
    permissionsOperator: PERMISSION_OPERATOR_AND,
  },
  {
    id: MENUID_POINT_LEADER_BOARD,
    level: 2,
    parentId: MENUID_POINT,
    label: LocalizedStringPoint.leaderBoardPage.title,
    value: ROUTE_NAME_POINT_LEADER_BOARD,
    icon: null,
    selected: false,
    permissions: ['POINT_GET_LEADER_BOARD'],
  },
  {
    id: MENUID_VOUCHER_USAGE,
    level: 2,
    parentId: MENUID_VOUCHER,
    label: LocalizedStringVoucher.voucherUsagePage.title,
    value: ROUTE_NAME_VOUCHER_USAGE,
    icon: null,
    selected: false,
    permissions: ['VOUCHER_GET_VOUCHER_USAGE'],
  },
  {
    id: MENUID_VOUCHER_CATEGORY,
    level: 2,
    parentId: MENUID_VOUCHER,
    label: LocalizedStringVoucher.voucherCategoryScreen.title,
    value: ROUTE_NAME_VOUCHER_CATEGORY,
    icon: null,
    selected: false,
    permissions: ['VOUCHER_GET_CATEGORY'],
  },
  {
    id: MENUID_DISCUSSION_DISCUSSION,
    level: 2,
    parentId: MENUID_DISCUSSION,
    label: LocalizedStringDiscussion.discussionPage.title,
    value: ROUTE_NAME_DISCUSSION,
    icon: null,
    selected: false,
    permissions: ['DISCUS_GET_ALL_DISCUS'],
  },
  {
    id: MENUID_DISCUSSION_DISCUSSION_COMMENT,
    level: 2,
    parentId: MENUID_DISCUSSION,
    label: LocalizedStringDiscussion.discussionCommentPage.title,
    value: ROUTE_NAME_DISCUSSION_DISCUSSION_COMMENT,
    icon: null,
    selected: false,
    permissions: ['DISCUS_GET_ALL_COMMENTS', 'DISCUS_GET_COMMENT'],
  },
  {
    id: MENUID_DISCUSSION_REPORTED_COMMENT,
    level: 2,
    parentId: MENUID_DISCUSSION,
    label: LocalizedStringDiscussion.reportedCommentPage.title,
    value: ROUTE_NAME_DISCUSSION_REPORTED_COMMENT,
    icon: null,
    selected: false,
    permissions: ['DISCUS_GET_REPORTED_COMMENT', 'DISCUS_HANDLE_REPORTED_COMMENT'],
  },
  {
    id: MENUID_FORUM_CATEGORY,
    level: 2,
    parentId: MENUID_FORUM,
    label: LocalizedStringForum.forumCategoryPage.title,
    value: ROUTE_NAME_FORUM_CATEGORY,
    icon: null,
    selected: false,
    permissions: ['FORUM_CREATE_FORUM_CATEGORY'],
  },
  {
    id: MENUID_FORUM_POST,
    level: 2,
    parentId: MENUID_FORUM,
    label: LocalizedStringForum.forumPostPage.title,
    value: ROUTE_NAME_FORUM_POST,
    icon: null,
    selected: false,
    permissions: ['FORUM_CREATE_POST'],
  },
  {
    id: MENUID_GAMEQR_CATEGORY,
    level: 2,
    parentId: MENUID_GAMEQR,
    label: LocalizedStringGameQR.gameQRCategoryPage.title,
    value: ROUTE_NAME_GAMEQR_CATEGORY,
    icon: null,
    selected: false,
    permissions: ['GAMEQR_CREATE_CATEGORY'],
  },
  {
    id: MENUID_GAMEQR_CHALLENGE,
    level: 2,
    parentId: MENUID_GAMEQR,
    label: LocalizedStringGameQR.challengePage.title,
    value: ROUTE_NAME_GAMEQR_CHALLENGE,
    icon: null,
    selected: false,
    permissions: ['GAMEQR_GET_CHALLENGE'],
  },
  {
    id: MENUID_GAMEQR_COMPLETED_CHALLENGE,
    level: 2,
    parentId: MENUID_GAMEQR,
    label: LocalizedStringGameQR.completedChallengePage.title,
    value: ROUTE_NAME_GAMEQR_COMPLETED_CHALLENGE,
    icon: null,
    selected: false,
    permissions: ['GAMEQR_GET_COMPLETED_CHALLENGE'],
  },
  {
    id: MENUID_GAMEQR_SCANNED_CODE,
    level: 2,
    parentId: MENUID_GAMEQR,
    label: LocalizedStringGameQR.scannedCodePage.title,
    value: ROUTE_NAME_GAMEQR_SCANNED_CODE,
    icon: null,
    selected: false,
    permissions: ['GAMEQR_GET_SCANNED_CODE'],
  },
  {
    id: MENUID_VOUCHER_VOUCHER,
    level: 2,
    parentId: MENUID_VOUCHER,
    label: LocalizedStringVoucher.voucherPage.title,
    value: ROUTE_NAME_VOUCHER_VOUCHER,
    icon: null,
    selected: false,
    permissions: ['VOUCHER_GET_VOUCHER'],
  },
  {
    id: MENUID_VOUCHER_TOKEN_ASSIGNMENT,
    level: 2,
    parentId: MENUID_VOUCHER,
    label: LocalizedStringVoucher.voucherTokenAssignment.title,
    value: ROUTE_NAME_VOUCHER_TOKEN_ASSIGNMENT,
    icon: null,
    selected: false,
    permissions: ['VOUCHER_GET_TOKEN_ASSIGNMENT'],
  },
  {
    id: MENUID_VOUCHER_GIFT_VOUCHER_REDEMPTION,
    level: 2,
    parentId: MENUID_VOUCHER,
    label: LocalizedStringVoucher.giftVoucherRedemptionPage.title,
    value: ROUTE_NAME_VOUCHER_GIFT_VOUCHER_REDEMPTION,
    icon: null,
    selected: false,
    permissions: ['VOUCHER_GET_TOKEN_ASSIGNMENT_REDEMPTION'],
  },
  {
    id: MENUID_VOUCHER_PROMOTED_MERCHANT,
    level: 2,
    parentId: MENUID_VOUCHER,
    label: LocalizedStringVoucher.promotedMerchantPage.title,
    value: ROUTE_NAME_VOUCHER_PROMOTED_MERCHANT,
    icon: null,
    selected: false,
    permissions: ['VOUCHER_GET_VOUCHER'],
  },
  {
    id: MENUID_POINT_VOID_POINT_REQUEST,
    level: 2,
    parentId: MENUID_POINT,
    label: LocalizedStringPoint.voidPointRequestPage.title,
    value: ROUTE_NAME_VOID_POINT_REQUEST,
    icon: null,
    selected: false,
    permissions: ['POINT_GET_VOID_POINT_REQUEST'],
  },
  {
    id: MENUID_VOUCHER_TOKEN_IMPORT,
    level: 2,
    parentId: MENUID_VOUCHER,
    label: LocalizedStringVoucher.tokenImportPage.title,
    value: ROUTE_NAME_VOUCHER_TOKEN_IMPORT,
    icon: null,
    selected: false,
    permissions: ['VOUCHER_GET_VOUCHER_IMPORT'],
  },
  {
    id: MENUID_VOUCHER_TOKEN_REQUEST,
    level: 2,
    parentId: MENUID_VOUCHER,
    label: LocalizedStringVoucher.tokenRequestPage.title,
    value: ROUTE_NAME_VOUCHER_TOKEN_REQUEST,
    icon: null,
    selected: false,
    permissions: ['VOUCHER_CREATE_TOKEN_REQUEST'],
  },
  {
    id: MENUID_VOUCHER_TOKEN,
    level: 2,
    parentId: MENUID_VOUCHER,
    label: LocalizedStringVoucher.voucherTokenPage.title,
    value: ROUTE_NAME_VOUCHER_TOKEN,
    icon: null,
    selected: false,
    permissions: ['VOUCHER_GET_TOKEN'],
  },
  {
    id: MENUID_VOUCHER_PURCHASE_REQUEST,
    level: 2,
    parentId: MENUID_VOUCHER,
    label: LocalizedStringVoucher.voucherPurchaseRequestPage.title,
    value: ROUTE_NAME_VOUCHER_PURCHASE_REQUEST,
    icon: null,
    selected: false,
    permissions: ['VOUCHER_GET_PURCHASE_REQUEST'],
  },
  {
    id: MENUID_PARTNER_MERCHANT,
    level: 2,
    parentId: MENUID_PARTNER,
    label: LocalizedStringPartner.partnerMerchantPage.title,
    value: ROUTE_NAME_PARTNER_MERCHANT,
    icon: null,
    selected: false,
    permissions: ['MERCHANT_CREATE_MERCHANT'],
  },
  {
    id: MENUID_EVENT_CATEGORY,
    level: 2,
    parentId: MENUID_EVENT,
    label: LocalizedStringEvent.categoryPage.title,
    value: ROUTE_NAME_EVENT_CATEGORY,
    icon: null,
    selected: false,
    permissions: ['EVENT_GET_CATEGORY'],
  },
  {
    id: MENUID_EVENT_SESSION_CATEGORY,
    level: 2,
    parentId: MENUID_EVENT,
    label: LocalizedStringEvent.sessionCategoryPage.title,
    value: ROUTE_NAME_SESSION_CATEGORY,
    icon: null,
    selected: false,
    permissions: ['EVENT_CREATE_SESSION_CATEGORY'],
  },
  {
    id: MENUID_EVENT_SPONSOR_CATEGORY,
    level: 2,
    parentId: MENUID_EVENT,
    label: LocalizedStringEvent.sponsorCategoryPage.title,
    value: ROUTE_NAME_SPONSOR_CATEGORY,
    icon: null,
    selected: false,
    permissions: ['EVENT_CREATE_SPONSOR_CATEGORY'],
  },
  {
    id: MENUID_EVENT_SPEAKER,
    level: 2,
    parentId: MENUID_EVENT,
    label: LocalizedStringEvent.speakerPage.title,
    value: ROUTE_NAME_SPEAKER,
    icon: null,
    selected: false,
    permissions: ['EVENT_CREATE_SPEAKER'],
  },
  {
    id: MENUID_EVENT_LOCATION,
    level: 2,
    parentId: MENUID_EVENT,
    label: LocalizedStringEvent.locationPage.title,
    value: ROUTE_NAME_LOCATION,
    icon: null,
    selected: false,
    permissions: ['EVENT_GET_LOCATION'],
  },
  {
    id: MENUID_EVENT_ROOM,
    level: 2,
    parentId: MENUID_EVENT,
    label: LocalizedStringEvent.roomPage.title,
    value: ROUTE_NAME_ROOM,
    icon: null,
    selected: false,
    permissions: ['EVENT_CREATE_ROOM'],
  },
  {
    id: MENUID_EVENT_SPONSOR,
    level: 2,
    parentId: MENUID_EVENT,
    label: LocalizedStringEvent.sponsorPage.title,
    value: ROUTE_NAME_SPONSOR,
    icon: null,
    selected: false,
    permissions: ['EVENT_CREATE_SPONSOR'],
  },
  {
    id: MENUID_EVENT_EVENT,
    level: 2,
    parentId: MENUID_EVENT,
    label: LocalizedStringEvent.eventPage.title,
    value: ROUTE_NAME_EVENT,
    icon: null,
    selected: false,
    permissions: ['EVENT_CREATE_EVENT'],
  },
  {
    id: MENUID_EVENT_SESSION,
    level: 2,
    parentId: MENUID_EVENT,
    label: LocalizedStringEvent.sessionPage.title,
    value: ROUTE_NAME_SESSION,
    icon: null,
    selected: false,
    permissions: ['EVENT_CREATE_SESSION'],
  },
  {
    id: MENUID_EVENT_EVENT_INVITATION,
    level: 2,
    parentId: MENUID_EVENT,
    label: LocalizedStringEvent.eventInvitationPage.title,
    value: ROUTE_NAME_EVENT_INVITATION,
    icon: null,
    selected: false,
    permissions: ['EVENT_CREATE_EVENT_INVITATION'],
  },
  {
    id: MENUID_EVENT_EVENT_REGISTRATION,
    level: 2,
    parentId: MENUID_EVENT,
    label: LocalizedStringEvent.eventRegistrationPage.title,
    value: ROUTE_NAME_EVENT_REGISTRATION,
    icon: null,
    selected: false,
    permissions: ['EVENT_CREATE_EVENT_REGISTRATION'],
  },
  {
    id: MENUID_EVENT_SESSION_INVITATION,
    level: 2,
    parentId: MENUID_EVENT,
    label: LocalizedStringEvent.sessionInvitationPage.title,
    value: ROUTE_NAME_SESSION_INVITATION,
    icon: null,
    selected: false,
    permissions: ['EVENT_CREATE_SESSION_INVITATION'],
  },
  {
    id: MENUID_EVENT_SESSION_REGISTRATION,
    level: 2,
    parentId: MENUID_EVENT,
    label: LocalizedStringEvent.sessionRegistrationPage.title,
    value: ROUTE_NAME_SESSION_REGISTRATION,
    icon: null,
    selected: false,
    permissions: ['EVENT_CREATE_SESSION_REGISTRATION'],
  },
  {
    id: MENUID_EVENT_OPERATOR_MAPPING,
    level: 2,
    parentId: MENUID_EVENT,
    label: LocalizedStringEventOperator.mappingPage.title,
    value: ROUTE_NAME_EVENT_OPERATOR_MAPPING,
    icon: null,
    selected: false,
    permissions: ['EVENT_GET_EVENT_OPERATOR_MAPPING'],
  },
  {
    id: MENUID_SESSION_OPERATOR_MAPPING,
    level: 2,
    parentId: MENUID_EVENT,
    label: LocalizedStringSessionOperator.mappingPage.title,
    value: ROUTE_NAME_SESSION_OPERATOR_MAPPING,
    icon: null,
    selected: false,
    permissions: ['EVENT_GET_SESSION_OPERATOR_MAPPING'],
  },
  {
    id: MENUID_EVENT_OPERATOR_EVENT,
    level: 2,
    parentId: MENUID_EVENT_OPERATOR,
    label: LocalizedStringEventOperator.eventOperatorEventPage.title,
    value: ROUTE_NAME_EVENT_OPERATOR_EVENT,
    icon: null,
    selected: false,
    permissions: ['EVENT_GET_EVENT_FOR_OPERATOR'],
  },
  {
    id: MENUID_EVENT_OPERATOR_INVITATION,
    level: 2,
    parentId: MENUID_EVENT_OPERATOR,
    label: LocalizedStringEventOperator.eventInvitationPage.title,
    value: ROUTE_NAME_EVENT_OPERATOR_INVITATION,
    icon: null,
    selected: false,
    permissions: ['EVENT_GET_EVENT_INVITATION_FOR_OPERATOR'],
  },
  {
    id: MENUID_EVENT_OPERATOR_REGISTRATION,
    level: 2,
    parentId: MENUID_EVENT_OPERATOR,
    label: LocalizedStringEventOperator.eventOperatorRegistrationPage.title,
    value: ROUTE_NAME_EVENT_OPERATOR_REGISTRATION,
    icon: null,
    selected: false,
    permissions: ['EVENT_GET_EVENT_REGISTRATION_FOR_OPERATOR'],
  },
  {
    id: MENUID_SESSION_OPERATOR_SESSION,
    level: 2,
    parentId: MENUID_EVENT_OPERATOR,
    label: LocalizedStringSessionOperator.sessionPage.title,
    value: ROUTE_NAME_SESSION_OPERATOR_SESSION,
    icon: null,
    selected: false,
    permissions: ['EVENT_GET_SESSION_FOR_OPERATOR'],
  },
  {
    id: MENUID_SESSION_OPERATOR_INVITATION,
    level: 2,
    parentId: MENUID_EVENT_OPERATOR,
    label: LocalizedStringSessionOperator.sessionInvitationPage.title,
    value: ROUTE_NAME_SESSION_OPERATOR_INVITATION,
    icon: null,
    selected: false,
    permissions: ['EVENT_GET_SESSION_INVITATION_FOR_OPERATOR'],
  },
  {
    id: MENUID_SESSION_OPERATOR_REGISTRATION,
    level: 2,
    parentId: MENUID_EVENT_OPERATOR,
    label: LocalizedStringSessionOperator.sessionRegistrationPage.title,
    value: ROUTE_NAME_SESSION_OPERATOR_REGISTRATION,
    icon: null,
    selected: false,
    permissions: ['EVENT_GET_SESSION_REGISTRATION_FOR_OPERATOR'],
  },
  {
    id: MENUID_ACTIVITY_REWARD_DAILY_CHECKIN,
    level: 2,
    parentId: MENUID_ACTIVITY_REWARD,
    label: LocalizedStringActivityReward.dailyCheckinPage.title,
    value: ROUTE_NAME_DAILY_CHECKIN,
    icon: null,
    selected: false,
    permissions: ['ACTIVITYREWARD_GET_DAILY_CHECKIN'],
  },
  {
    id: MENUID_ACTIVITY_REWARD_REGISTRATION_REFERRAL,
    level: 2,
    parentId: MENUID_ACTIVITY_REWARD,
    label: LocalizedStringActivityReward.registrationReferralPage.title,
    value: ROUTE_NAME_REGISTRATION_REFERRAL,
    icon: null,
    selected: false,
    permissions: ['ACTIVITYREWARD_GET_REGISTRATION_REFERRAL'],
  },
  {
    id: MENUID_DFORM_FORM,
    level: 2,
    parentId: MENUID_DFORM,
    label: LocalizedStringDForm.formPage.title,
    value: ROUTE_NAME_DFORM_FORM,
    icon: null,
    selected: false,
    permissions: ['DFORM_GET_FORM'],
  },
  {
    id: MENUID_DFORM_FIELD,
    level: 2,
    parentId: MENUID_DFORM,
    label: LocalizedStringDForm.fieldPage.title,
    value: ROUTE_NAME_DFORM_FIELD,
    icon: null,
    selected: false,
    permissions: ['DFORM_GET_FIELD'],
  },
  {
    id: MENUID_DFORM_PREVIEW,
    level: 2,
    parentId: MENUID_DFORM,
    label: LocalizedStringDForm.previewPage.title,
    value: ROUTE_NAME_DFORM_PREVIEW,
    icon: null,
    selected: false,
    permissions: ['DFORM_GET_FORM'],
  },
  {
    id: MENUID_DFORM_RESPONSE,
    level: 2,
    parentId: MENUID_DFORM,
    label: LocalizedStringDForm.responsePage.title,
    value: ROUTE_NAME_DFORM_RESPONSE,
    icon: null,
    selected: false,
    permissions: ['DFORM_GET_FORM_RESPONSE'],
  },
  {
    id: MENUID_PAYMENT_PAYMENT,
    level: 2,
    parentId: MENUID_PAYMENT,
    label: LocalizedStringPayment.paymentPage.title,
    value: ROUTE_NAME_PAYMENT,
    icon: null,
    selected: false,
    permissions: ['PAYMENT_GET_PAYMENT'],
  },
  {
    id: MENUID_USER_JOB_JOB,
    level: 2,
    parentId: MENUID_USER_JOB,
    label: LocalizedStringUserJob.jobPage.title,
    value: ROUTE_NAME_JOB,
    icon: null,
    selected: false,
    permissions: ['USERJOB_GET_JOB'],
  },
  {
    id: MENUID_DOCUMENT_GENERATOR_TEMPLATE,
    level: 2,
    parentId: MENUID_DOCUMENT_GENERATOR,
    label: LocalizedStringDocumentGenerator.templatePage.title,
    value: ROUTE_DOCUMENT_GENERATOR_TEMPLATE,
    icon: null,
    selected: false,
    permissions: ['DOCGEN_GET_DOCUMENT_TEMPLATE'],
  },
  {
    id: MENUID_DOCUMENT_GENERATOR_DOCUMENT,
    level: 2,
    parentId: MENUID_DOCUMENT_GENERATOR,
    label: LocalizedStringDocumentGenerator.documentPage.title,
    value: ROUTE_DOCUMENT_GENERATOR_DOCUMENT,
    icon: null,
    selected: false,
    permissions: ['DOCGEN_GET_DOCUMENT_TEMPLATE'],
  },
];

export default menuList;
