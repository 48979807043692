
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_JOB, MENUID_USER_JOB_JOB, PAGE_MODE_TABLE, RXFORM_JOB,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE, DATE_TIME_FORMAT_WITHOUT_PIPE,
  DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE,
} from '../../constant';
import {
  toMoment, transformInitialValues, transformUserDropdownData,
  debounceSearch,
} from '../../../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  clearJobs, setActiveSideMenuItem, setAdvancedFilterDialogSelectedFilterString,
  setAlertConfirmationMessage, setAlertErrorMessage, setJobSearchText,
  setJobSelectedPageSize, setJobSelectedOrderBy, setJobTappedId,
  downloadJobsAsync, downloadDeleteJobAsync, setProfileAdvancedFilterDialogSelectedFilterString,
  setProfileSearchText, clearProfiles,
} from '../../redux/action';
import JobPage from './job.presentation';
import { downloadProfilesAsync } from '../../../../redux/action';

const getInitialValues = (state) => {
  const { jobs, uiFunctionalPage, uiJob } = state;
  const { data } = jobs;
  const { downloadingDeleting, tappedId } = uiJob;
  const { pageMode } = uiFunctionalPage;
  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    startTime: found?.startTime ? toMoment(found?.startTime).format(DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE) : '',
    endTime: found?.endTime ? toMoment(found?.endTime).format(DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE) : '',
    deletedAfter: found?.deletedAfter ? toMoment(found?.deletedAfter).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
  }) : {
    startTime: '',
    endTime: '',
    deletedAfter: '',
  };
  return result;
};

const onClickFileDownloader = async (filePath, fileName) => {
  const getFileType = filePath.split('.').pop();
  await fetch(`${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${filePath}`)
    .then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = `${fileName}.${getFileType}`;
        alink.click();
      });
    });
};

const searchProfileDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  downloading: state.uiJob.downloading,
  downloadingDeleting: state.uiJob.downloadingDeleting,
  initialValues: getInitialValues(state),
  tappedId: state.uiFunctionalPage.tappedId || '',
  profiles: transformUserDropdownData(state.profiles.data),
  loadingProfiles: state.uiProfile.downloading,
});
const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_USER_JOB_JOB));
    dispatch(setJobSearchText(''));
    dispatch(clearJobs());
    dispatch(setJobSelectedPageSize(20));
    dispatch(setJobSelectedOrderBy(INITIAL_ORDER_BY_JOB));
    dispatch(downloadJobsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setAdvancedFilterDialogSelectedFilterString(filterString));
    dispatch(clearJobs());
    dispatch(downloadJobsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_JOB));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadJobsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setJobSelectedPageSize(pageSize));
    dispatch(downloadJobsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case GlobalLocalizedString.common.msgDeleteConfirmation:
        dispatch(downloadDeleteJobAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default:
        dispatch(clearJobs())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setJobTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onRefresh: (pageSize) => {
    dispatch(setJobSelectedPageSize(pageSize));
    dispatch(clearJobs());
    dispatch(downloadJobsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearJobs());
    dispatch(setJobSelectedOrderBy(INITIAL_ORDER_BY_JOB));
    dispatch(downloadJobsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setJobSearchText(text));
      dispatch(clearJobs());
      await dispatch(downloadJobsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setJobSelectedOrderBy(orderBy));
    dispatch(clearJobs());
    dispatch(downloadJobsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setJobTappedId(id));
    dispatch(downloadDeleteJobAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDownloadPressed: (filePath, fileName) => {
    onClickFileDownloader(filePath, fileName);
  },
  onChangeProfileText: async (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchProfileDebounce(dispatch);
    }
  },
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(JobPage);
