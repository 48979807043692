import {
  buildQueryParams, removeBase64StringPrefix, sendGetRequest, sendPutRequest, transformQueryResult,
} from '../../helper';
import {
  REST_URL_NOTIFICATION_TASKS, REST_URL_VIEW_NOTIFICATION_TASK, REST_URL_CANCEL_NOTIFICATION_TASK,
  REST_URL_EMAILS, REST_URL_VIEW_EMAIL, REST_URL_RESEND_EMAIL,
  REST_URL_SMSES, REST_URL_VIEW_SMS, REST_URL_RESEND_SMS,
  REST_URL_WHATSAPPS, REST_URL_VIEW_WHATSAPP, REST_URL_RESEND_WHATSAPP,
  REST_URL_PUSH_NOTIFICATIONS, REST_URL_VIEW_PUSH_NOTIFICATION, REST_URL_RESEND_PUSH_NOTIFICATION,
} from './constant';

export * from '../../helper';

export const downloadNotificationTasks = async (pageNumber, pageSize, orderBy, searchText,
  token, filterString) => {
  const taskUrl = REST_URL_NOTIFICATION_TASKS.replace(/\{filterString\}/, filterString);
  const url = buildQueryParams(taskUrl, pageNumber, pageSize, orderBy,
    searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadNotificationTask = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_NOTIFICATION_TASK.replace(/\{id\}/, id), token);
  return response;
};

export const cancelNotificationTask = async (id, token) => {
  await sendPutRequest(REST_URL_CANCEL_NOTIFICATION_TASK.replace(/\{id\}/, id), {}, token);
};

export const downloadEmails = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_EMAILS.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_EMAILS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadEmail = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_EMAIL.replace(/\{id\}/, id), token);
  return response;
};

export const resendEmail = async (id, token) => {
  await sendPutRequest(REST_URL_RESEND_EMAIL.replace(/\{id\}/, id), {}, token);
};

export const downloadSmses = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_SMSES.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_SMSES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadSms = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_SMS.replace(/\{id\}/, id), token);
  return response;
};

export const resendSms = async (id, token) => {
  await sendPutRequest(REST_URL_RESEND_SMS.replace(/\{id\}/, id), {}, token);
};

export const downloadWhatsapps = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_WHATSAPPS.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_WHATSAPPS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadWhatsapp = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_WHATSAPP.replace(/\{id\}/, id), token);
  return response;
};

export const resendWhatsapp = async (id, token) => {
  await sendPutRequest(REST_URL_RESEND_WHATSAPP.replace(/\{id\}/, id), {}, token);
};

export const downloadPushNotifications = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_PUSH_NOTIFICATIONS.concat(filterString), pageNumber, pageSize,
      orderBy, searchText)
    : buildQueryParams(REST_URL_PUSH_NOTIFICATIONS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadPushNotification = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_PUSH_NOTIFICATION.replace(/\{id\}/, id), token);
  return response;
};

export const resendPushNotification = async (id, token) => {
  await sendPutRequest(REST_URL_RESEND_PUSH_NOTIFICATION.replace(/\{id\}/, id), {}, token);
};

export const downloadImageAsBase64 = async (url) => {
  const base64 = await fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((res) => {
        reader.onloadend = () => {
          res(reader.result);
        };
      });
    });

  return removeBase64StringPrefix(base64);
};
