import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_MY_JOBS, MENUID_USER_JOB_MY_JOB, PAGE_MODE_TABLE, RXFORM_MY_JOB,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE, DATE_TIME_FORMAT_WITHOUT_PIPE,
  DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE,
} from '../../constant';
import { toMoment, transformInitialValues } from '../../../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  clearMyJobs, setActiveSideMenuItem, setAdvancedFilterDialogSelectedFilterString,
  setAlertConfirmationMessage, setAlertErrorMessage, setMyJobSearchText,
  setMyJobSelectedPageSize, setMyJobSelectedOrderBy, setMyJobTappedId,
  downloadMyJobsAsync, downloadDeleteMyJobAsync, cancelMyJobAsync,
} from '../../redux/action';
import MyJobPage from './my-job.presentation';

const getInitialValues = (state) => {
  const { myJobs, uiFunctionalPage, uiMyJob } = state;
  const { data } = myJobs;
  const { downloadingDeleting, tappedId } = uiMyJob;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    startTime: found?.startTime ? toMoment(found?.startTime).format(DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE) : '',
    endTime: found?.endTime ? toMoment(found?.endTime).format(DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE) : '',
    deletedAfter: found?.deletedAfter ? toMoment(found?.deletedAfter).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
  }) : {
    startTime: '',
    endTime: '',
    deletedAfter: '',
  };
  return result;
};

const onClickFileDownloader = async (filePath, fileName) => {
  const getFileType = filePath.split('.').pop();
  await fetch(`${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${filePath}`)
    .then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = `${fileName}.${getFileType}`;
        alink.click();
      });
    });
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  downloading: state.uiMyJob.downloading,
  downloadingDeleting: state.uiMyJob.downloadingDeleting,
  canceling: state.uiMyJob.canceling,
  initialValues: getInitialValues(state),
  tappedId: state.uiFunctionalPage.tappedId || '',
  currentJobStatus: state.myJobs.data[state.uiFunctionalPage.tappedId]
    ? state.myJobs.data[state.uiFunctionalPage.tappedId].jobStatus : '',
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_USER_JOB_MY_JOB));
    dispatch(setMyJobSearchText(''));
    dispatch(clearMyJobs());
    dispatch(setMyJobSelectedPageSize(20));
    dispatch(setMyJobSelectedOrderBy(INITIAL_ORDER_BY_MY_JOBS));
    dispatch(downloadMyJobsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setAdvancedFilterDialogSelectedFilterString(filterString));
    dispatch(clearMyJobs());
    dispatch(downloadMyJobsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_MY_JOB));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadMyJobsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setMyJobSelectedPageSize(pageSize));
    dispatch(downloadMyJobsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case GlobalLocalizedString.common.msgDeleteConfirmation:
        dispatch(downloadDeleteMyJobAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default:
        dispatch(cancelMyJobAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setMyJobTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onRefresh: (pageSize) => {
    dispatch(setMyJobSelectedPageSize(pageSize));
    dispatch(clearMyJobs());
    dispatch(downloadMyJobsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearMyJobs());
    dispatch(setMyJobSelectedOrderBy(INITIAL_ORDER_BY_MY_JOBS));
    dispatch(downloadMyJobsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setMyJobSearchText(text));
      dispatch(clearMyJobs());
      await dispatch(downloadMyJobsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setMyJobSelectedOrderBy(orderBy));
    dispatch(clearMyJobs());
    dispatch(downloadMyJobsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setMyJobTappedId(id));
    dispatch(downloadDeleteMyJobAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDownloadPressed: (filePath, fileName) => {
    onClickFileDownloader(filePath, fileName);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MyJobPage);
