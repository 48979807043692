import moment from 'moment';
import { isEmpty } from 'lodash';
import {
  TIME_FORMAT,
  RXFIELD_USERNAME, RXFIELD_PASSWORD,
  RXFIELD_PROVINCE_NAME, RXFIELD_PROVINCE_TIME_ZONE,
  RXFIELD_CITY_NAME,
  RXFIELD_BRANCH_ADDRESS, RXFIELD_BRANCH_AREA, RXFIELD_BRANCH_CITY,
  RXFIELD_BRANCH_END_AFTER_MIN, RXFIELD_BRANCH_END_BEFORE_MIN, RXFIELD_BRANCH_START_WORKING_HOUR,
  RXFIELD_BRANCH_END_WORKING_HOUR, RXFIELD_BRANCH_GOOGLE_MAP_URL, RXFIELD_BRANCH_LATITUDE,
  RXFIELD_BRANCH_LONGITUDE, RXFIELD_BRANCH_NAME,
  RXFIELD_BRANCH_START_AFTER_MIN, RXFIELD_BRANCH_START_BEFORE_MIN,
  RXFIELD_BRANCH_AUTO_CLOCK_OUT_AFTER_MINUTES, RXFIELD_BRANCH_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES,
  RXFIELD_PERMISSION_NAME, RXFIELD_USER_USERNAME,
  RXFIELD_USER_FULLNAME,
  RXFIELD_ROLE_DESCRIPTION, RXFIELD_ROLE_NAME,
  RXFIELD_API_KEY_SCOPE, RXFIELD_API_KEY_CONSUMER, RXFIELD_API_KEY_OWNER,
  RXFIELD_APPLICATION_ICON, RXFIELD_APPLICATION_NAME,
  RXFIELD_APPLICATION_ORDER, RXFIELD_APPLICATION_PERMISSION, RXFIELD_APPLICATION_URL,
  RXFIELD_PROFILE_BRANCH, RXFIELD_PROFILE_COMPANY_NAME, RXFIELD_PROFILE_DEPARTMENT,
  RXFIELD_PROFILE_EMPLOYEE_ID, RXFIELD_PROFILE_GENDER,
  RXFIELD_PROFILE_JOB_TITLE,
  RXFIELD_SEND_PERSONAL_NOTIFICATION_TITLE, RXFIELD_SEND_PERSONAL_NOTIFICATION_BODY,
  RXFIELD_SERVICE_NAME, RXFIELD_SERVICE_DESCRIPTION,
  RXFIELD_SCOPE_NAME, RXFIELD_SCOPE_DESCRIPTION,
  RXFIELD_BANNER_CLICK_ACTION, RXFIELD_BANNER_MEDIA, RXFIELD_BANNER_ORDER, RXFIELD_BANNER_TITLE,
  RXFIELD_BANNER_VALID_FROM, RXFIELD_BANNER_VALID_UNTIL,
  RXFIELD_COMPANY_CITY, RXFIELD_COMPANY_NAME,
  RXFIELD_DISTRICT_NAME, RXFIELD_SUBDISTRICT_NAME,
  RXFIELD_DISTRICT_CITY, RXFIELD_SUBDISTRICT_DISTRICT,
  RXFIELD_SETTINGS_NEW_PASSWORD,
  RXFIELD_SETTINGS_OLD_PASSWORD,
  RXFIELD_SETTINGS_NEW_PASSWORD_RETYPE,
  RXFIELD_POPUP_BANNER_TITLE,
  RXFIELD_POPUP_BANNER_VALID_FROM,
  RXFIELD_POPUP_BANNER_VALID_UNTIL,
  RXFIELD_POPUP_BANNER_ORDER,
  RXFIELD_POPUP_BANNER_URL,
  RXFIELD_POPUP_BANNER_IMAGE,
  RXFIELD_CAPTCHA_TEXT,
  RXFIELD_WORKING_SHIFT_NAME,
  RXFIELD_WORKING_SHIFT_BRANCH,
  RXFIELD_WORKING_SHIFT_END_AFTER_MIN,
  RXFIELD_WORKING_SHIFT_END_BEFORE_MIN,
  RXFIELD_WORKING_SHIFT_END_WORKING_HOUR,
  RXFIELD_WORKING_SHIFT_START_AFTER_MIN,
  RXFIELD_WORKING_SHIFT_START_BEFORE_MIN,
  RXFIELD_WORKING_SHIFT_START_WORKING_HOUR,
  RXFIELD_WORKING_SHIFT_AUTO_CLOCK_OUT_AFTER_MINUTES,
  RXFIELD_WORKING_SHIFT_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES,
  ENUM_FIELD_TEXT,
  ENUM_FIELD_INTEGER,
  DEFAULT_MIN_INT_VALUE,
  DEFAULT_MAX_INT_VALUE,
  ENUM_FIELD_FLOAT,
  DEFAULT_MIN_FLOAT_VALUE,
  DEFAULT_MAX_FLOAT_VALUE,
  ENUM_FIELD_DATE,
  DEFAULT_MIN_DATE_VALUE,
  DATE_FORMAT,
  DEFAULT_MAX_DATE_VALUE,
  ENUM_FIELD_DATE_TIME,
  DATE_FORMAT_COMPLETE_WITHOUT_BAR,
  ENUM_FIELD_TIME,
  HOUR_FORMAT,
  ENUM_FIELD_BOOLEAN,
} from './constant';
import LocalizedString from './localization';

export const rxFormValidateRequiredFields = (values, requiredFields) => {
  const errors = {};
  requiredFields.forEach((field) => {
    if ((values[field] instanceof Array && values[field].length === 0)
      || (!values[field] && typeof values[field] !== 'number')
      || (((values[field] instanceof Array) === false) && typeof values[field] === 'object' && typeof values[field].value !== 'boolean' && !values[field].value)
      || (typeof values[field] === 'string' && values[field].match('<p>(.*?)</p>')
        && !values[field].match('<p>(.*?)</p>')[1])) {
      errors[field] = LocalizedString.common.errMsgRequired;
    }
  });
  return errors;
};

export const rxformValidateLogin = (values, props) => {
  const mainRequiredFields = [
    RXFIELD_USERNAME,
    RXFIELD_PASSWORD,
  ];
  const requiredFields = props.useCaptcha
    ? [...mainRequiredFields, RXFIELD_CAPTCHA_TEXT] : mainRequiredFields;

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateProvince = (values) => {
  const requiredFields = [
    RXFIELD_PROVINCE_NAME,
    RXFIELD_PROVINCE_TIME_ZONE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateCity = (values) => {
  const requiredFields = [
    RXFIELD_CITY_NAME,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

const rxformValidateWorkingHourFields = (values, requiredErrors) => {
  const errors = requiredErrors;

  const startHourOfDay = moment().startOf('day').format(TIME_FORMAT); // 00.00
  const endHourOfDay = moment().endOf('day').format(TIME_FORMAT); // 23.59
  const NIGHT_START_TIME = '20:00';
  const NIGHT_END_TIME = '03:59';

  if (moment(values.endWorkingHour, TIME_FORMAT)
    .isSame(moment(values.startWorkingHour, TIME_FORMAT))) {
    errors.endWorkingHour = LocalizedString.common.errMsgStartEndWorkingHour;
  }
  if (!(moment(values.startWorkingHour, TIME_FORMAT)
    .isBetween(moment(startHourOfDay, TIME_FORMAT), moment(NIGHT_END_TIME, TIME_FORMAT)))
    && moment(values.earliestClockInDriftTimeMinutes, TIME_FORMAT)
      .isAfter(moment(values.startWorkingHour, TIME_FORMAT))) {
    errors.earliestClockInDriftTimeMinutes = LocalizedString.common.errMsgEarliestClockInTime;
  }
  if (!(moment(values.endWorkingHour, TIME_FORMAT)
    .isBetween(moment(NIGHT_START_TIME, TIME_FORMAT), moment(endHourOfDay, TIME_FORMAT)))
    && moment(values.autoClockOutAfterMinutes, TIME_FORMAT)
      .isBefore(moment(values.endWorkingHour, TIME_FORMAT))) {
    errors.autoClockOutAfterMinutes = LocalizedString.common.errMsgAutoClockOut;
  }
  return errors;
};

export const rxformValidateBranch = (values) => {
  const requiredFields = [
    RXFIELD_BRANCH_ADDRESS,
    RXFIELD_BRANCH_AREA,
    RXFIELD_BRANCH_CITY,
    RXFIELD_BRANCH_END_AFTER_MIN,
    RXFIELD_BRANCH_END_BEFORE_MIN,
    RXFIELD_BRANCH_END_WORKING_HOUR,
    RXFIELD_BRANCH_GOOGLE_MAP_URL,
    RXFIELD_BRANCH_LATITUDE,
    RXFIELD_BRANCH_LONGITUDE,
    RXFIELD_BRANCH_NAME,
    RXFIELD_BRANCH_START_AFTER_MIN,
    RXFIELD_BRANCH_START_BEFORE_MIN,
    RXFIELD_BRANCH_START_WORKING_HOUR,
    RXFIELD_BRANCH_AUTO_CLOCK_OUT_AFTER_MINUTES,
    RXFIELD_BRANCH_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return rxformValidateWorkingHourFields(values, errors);
};

export const rxformValidateCompanyMdata = (values) => {
  const requiredFields = [
    RXFIELD_COMPANY_CITY,
    RXFIELD_COMPANY_NAME,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return rxformValidateWorkingHourFields(values, errors);
};

export const rxformValidatePermission = (values) => {
  const requiredFields = [
    RXFIELD_PERMISSION_NAME,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateUser = (values) => {
  const requiredFields = [
    RXFIELD_USER_USERNAME,
    RXFIELD_USER_FULLNAME,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateRole = (values) => {
  const requiredFields = [
    RXFIELD_ROLE_NAME,
    RXFIELD_ROLE_DESCRIPTION,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateApiKey = (values) => {
  const requiredFields = [
    RXFIELD_API_KEY_SCOPE,
    RXFIELD_API_KEY_CONSUMER,
    RXFIELD_API_KEY_OWNER,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateApplication = (values) => {
  const requiredFields = [
    RXFIELD_APPLICATION_ICON,
    RXFIELD_APPLICATION_NAME,
    RXFIELD_APPLICATION_ORDER,
    RXFIELD_APPLICATION_PERMISSION,
    RXFIELD_APPLICATION_URL,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateProfile = (values) => {
  const requiredFields = [
    RXFIELD_PROFILE_EMPLOYEE_ID,
    RXFIELD_PROFILE_GENDER,
    RXFIELD_PROFILE_JOB_TITLE,
    RXFIELD_PROFILE_DEPARTMENT,
    RXFIELD_PROFILE_COMPANY_NAME,
    RXFIELD_PROFILE_BRANCH,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (values.startWorkingHour && !values.endWorkingHour) {
    errors.endWorkingHour = LocalizedString.common.errMsgRequired;
  }
  if (!values.startWorkingHour && values.endWorkingHour) {
    errors.startWorkingHour = LocalizedString.common.errMsgRequired;
  }
  return rxformValidateWorkingHourFields(values, errors);
};

export const rxformValidateSendPersonalNotification = (values) => {
  const requiredFields = [
    RXFIELD_SEND_PERSONAL_NOTIFICATION_TITLE,
    RXFIELD_SEND_PERSONAL_NOTIFICATION_BODY,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateService = (values) => {
  const requiredFields = [
    RXFIELD_SERVICE_NAME,
    RXFIELD_SERVICE_DESCRIPTION,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateScope = (values) => {
  const requiredFields = [
    RXFIELD_SCOPE_NAME,
    RXFIELD_SCOPE_DESCRIPTION,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateBanner = (values) => {
  const requiredFields = [
    RXFIELD_BANNER_TITLE,
    RXFIELD_BANNER_CLICK_ACTION,
    RXFIELD_BANNER_VALID_FROM,
    RXFIELD_BANNER_VALID_UNTIL,
    RXFIELD_BANNER_ORDER,
    RXFIELD_BANNER_MEDIA,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!moment(values.validUntil).isAfter(moment(values.validFrom))) {
    errors.validUntil = LocalizedString.common.errMsgStartEndDate;
  }
  return errors;
};

export const rxformValidatePopUpBanner = (values) => {
  const requiredFields = [
    RXFIELD_POPUP_BANNER_TITLE,
    RXFIELD_POPUP_BANNER_URL,
    RXFIELD_POPUP_BANNER_ORDER,
    RXFIELD_POPUP_BANNER_VALID_FROM,
    RXFIELD_POPUP_BANNER_VALID_UNTIL,
    RXFIELD_POPUP_BANNER_IMAGE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!moment(values.validUntil).isAfter(moment(values.validFrom))) {
    errors.validUntil = LocalizedString.common.errMsgStartEndDate;
  }
  return errors;
};

export const rxformValidateDistrict = (values) => {
  const requiredFields = [
    RXFIELD_DISTRICT_NAME,
    RXFIELD_DISTRICT_CITY,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateSubdistrict = (values) => {
  const requiredFields = [
    RXFIELD_SUBDISTRICT_NAME,
    RXFIELD_SUBDISTRICT_DISTRICT,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateSettings = (values) => {
  const requiredFields = [
    RXFIELD_SETTINGS_OLD_PASSWORD,
    RXFIELD_SETTINGS_NEW_PASSWORD,
    RXFIELD_SETTINGS_NEW_PASSWORD_RETYPE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (values.newPasswordRetype && values.newPassword !== values.newPasswordRetype) {
    errors.newPasswordRetype = LocalizedString.common.errMsgDifferentRetypePassword;
  }
  return errors;
};

export const rxformValidateWorkingShift = (values) => {
  const requiredFields = [
    RXFIELD_WORKING_SHIFT_NAME,
    RXFIELD_WORKING_SHIFT_BRANCH,
    RXFIELD_WORKING_SHIFT_END_AFTER_MIN,
    RXFIELD_WORKING_SHIFT_END_BEFORE_MIN,
    RXFIELD_WORKING_SHIFT_END_WORKING_HOUR,
    RXFIELD_WORKING_SHIFT_START_AFTER_MIN,
    RXFIELD_WORKING_SHIFT_START_BEFORE_MIN,
    RXFIELD_WORKING_SHIFT_START_WORKING_HOUR,
    RXFIELD_WORKING_SHIFT_AUTO_CLOCK_OUT_AFTER_MINUTES,
    RXFIELD_WORKING_SHIFT_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return rxformValidateWorkingHourFields(values, errors);
};

export const rxformValidateDynamicForm = (values, fields) => {
  const requiredFields = fields.filter((x) => x.required).map((x) => x.name);
  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!isEmpty(values)) {
    fields.map((field) => {
      if (values[field.name] || (values[field.name] && field.required)) {
        if (field.fieldType === ENUM_FIELD_TEXT) {
          if (field.textMinLength > 0 && values[field.name].length < field.textMinLength) {
            errors[field.name] = field.validationMessage ? field.validationMessage
              : LocalizedString.common.errMsgMinLength.replace(/\{minLength\}/, field.textMinLength);
          }
        } if (field.fieldType === ENUM_FIELD_INTEGER) {
          const value = Number(values[field.name].replace(/,/, '.'));
          const intMinValue = Number(field.intMinValue);
          const intMaxValue = Number(field.intMaxValue);
          if (intMinValue > DEFAULT_MIN_INT_VALUE && value < intMinValue) {
            errors[field.name] = field.validationMessage ? field.validationMessage
              : LocalizedString.common.errMsgMinValue.replace(/\{minValue\}/, field.intMinValue);
          }
          if (intMaxValue < DEFAULT_MAX_INT_VALUE && value > intMaxValue) {
            errors[field.name] = field.validationMessage ? field.validationMessage
              : LocalizedString.common.errMsgMaxValue.replace(/\{maxValue\}/, field.intMaxValue);
          }
        } if (field.fieldType === ENUM_FIELD_FLOAT) {
          const value = parseFloat(values[field.name].replace(/,/, '.'));
          const floatMinValue = parseFloat(field.floatMinValue);
          const floatMaxValue = parseFloat(field.floatMaxValue);
          if (floatMinValue > DEFAULT_MIN_FLOAT_VALUE && value < floatMinValue) {
            errors[field.name] = field.validationMessage ? field.validationMessage
              : LocalizedString.common.errMsgMinValue.replace(/\{minValue\}/, field.floatMinValue);
          }
          if (floatMaxValue < DEFAULT_MAX_FLOAT_VALUE && value > floatMaxValue) {
            errors[field.name] = field.validationMessage ? field.validationMessage
              : LocalizedString.common.errMsgMaxValue.replace(/\{maxValue\}/, field.floatMaxValue);
          }
        } if (field.fieldType === ENUM_FIELD_DATE) {
          const value = moment(values[field.name]);
          const dateTimeMinValue = moment(field.dateTimeMinValue);
          const dateTimeMaxValue = moment(field.dateTimeMaxValue);

          if (field.dateTimeMinValue !== DEFAULT_MIN_DATE_VALUE && value.diff(dateTimeMinValue, 'days') < 0) {
            errors[field.name] = field.validationMessage ? field.validationMessage
              : LocalizedString.common.errMsgMinValue.replace(/\{minValue\}/, dateTimeMinValue.format(DATE_FORMAT));
          }
          if (dateTimeMaxValue !== DEFAULT_MAX_DATE_VALUE && value.diff(dateTimeMaxValue, 'days') > 0) {
            errors[field.name] = field.validationMessage ? field.validationMessage
              : LocalizedString.common.errMsgMaxValue.replace(/\{maxValue\}/, dateTimeMaxValue.format(DATE_FORMAT));
          }
        } if (field.fieldType === ENUM_FIELD_DATE_TIME) {
          const value = moment(values[field.name]);
          const dateTimeMinValue = moment(field.dateTimeMinValue);
          const dateTimeMaxValue = moment(field.dateTimeMaxValue);
          if (value.diff(dateTimeMinValue) < 0) {
            errors[field.name] = field.validationMessage ? field.validationMessage
              : LocalizedString.common.errMsgMinValue.replace(/\{minValue\}/, dateTimeMinValue.format(DATE_FORMAT_COMPLETE_WITHOUT_BAR));
          }
          if (value.diff(dateTimeMaxValue) > 0) {
            errors[field.name] = field.validationMessage ? field.validationMessage
              : LocalizedString.common.errMsgMaxValue.replace(/\{maxValue\}/, dateTimeMaxValue.format(DATE_FORMAT_COMPLETE_WITHOUT_BAR));
          }
        } if (field.fieldType === ENUM_FIELD_TIME) {
          const value = moment(values[field.name]);
          const dateTimeMinValue = moment(field.dateTimeMinValue);
          const dateTimeMaxValue = moment(field.dateTimeMaxValue);
          if (field.dateTimeMinValue !== DEFAULT_MIN_DATE_VALUE
            && value.diff(dateTimeMinValue) < 0) {
            errors[field.name] = field.validationMessage ? field.validationMessage
              : LocalizedString.common.errMsgMinValue.replace(/\{minValue\}/, dateTimeMinValue.format(HOUR_FORMAT));
          }
          if (dateTimeMaxValue !== DEFAULT_MAX_DATE_VALUE && value.diff(dateTimeMaxValue) > 0) {
            errors[field.name] = field.validationMessage ? field.validationMessage
              : LocalizedString.common.errMsgMaxValue.replace(/\{maxValue\}/, dateTimeMaxValue.format(HOUR_FORMAT));
          }
        } if (!isEmpty(field.textRegexValidation)) {
          if (!values[field.name].match(field.textRegexValidation)) {
            errors[field.name] = field.validationMessage ? field.validationMessage
              : LocalizedString.common.errMsgInvalidFormat;
          }
        } if (field.fieldType === ENUM_FIELD_BOOLEAN) {
          if (typeof values[field.name] !== 'boolean') {
            errors[field.name] = LocalizedString.common.errMsgRequired;
          }
        }
      }
      return null;
    });
  }

  const booleanFields = fields.filter((x) => x.required && x.fieldType === ENUM_FIELD_BOOLEAN);
  booleanFields.map((field) => {
    const value = values[field.name];
    if (typeof value === 'boolean') {
      delete errors[field.name];
    }
    return field;
  });
  return errors;
};
